<!--新建/编辑任务-->
<template>
   <div class="add-task-popup">
       <popup @closeEvent="closeEvent" v-if="addTaskVisible">
            <div slot="popup-name">{{taskDetailObj.taskGroupId && taskDetailObj.taskGroupId !== '' ? '编辑任务' : '新建任务'}}</div>
            <div slot="popup-tip">{{taskDetailObj.taskGroupId && taskDetailObj.taskGroupId !== '' ? '编辑' : '新建'}}智能文档抽取任务</div>
            <div slot="popup-con">
                <el-form :model="ruleForm" :label-position="'top'" ref="ruleForm" label-width="100px" class="add-task-ruleForm">
                    <el-form-item label="任务名称" prop="name">
                        <span slot="label">
                            <span>任务名称</span>
                            <span class="red" style="margin-left:3px">*</span>
                        </span>
                        <el-input v-model.trim="ruleForm.name" placeholder="请输入任务名称" maxlength="30"></el-input>
                    </el-form-item>
                    <el-form-item label="所属分类" prop="classifi">
                         <span slot="label">
                            <span>所属分类</span>
                            <span class="red" style="margin-left:3px">*</span>
                        </span>
                        <!-- <el-cascader :options="treeData" filterable  :props="{ checkStrictly: true,multiple:false}" v-model="ruleForm.classifi" placeholder="请选择所属分类">
                            <template slot-scope="{ node, data }">
                                <span>{{ data.taskTypeName }}</span>
                            </template>
                        </el-cascader> -->
                        <el-dropdown  trigger="click"  :hide-on-click="false" @visible-change="elDropdownvisibleChange" id="el-dropdown-relation">
                            <span :class="[
                                'el-dropdown-link',
                                'el-dropdown-link-workorder',
                                workOrderSelectIng? 'active-el-dropdown-link': '',]">
                                <span
                                    v-if="ruleForm.classifi && ruleForm.classifi.length != 0"
                                    class="el-dropdown-work-order-content">
                                    {{classifiName}}
                                </span>
                                <span v-else-if="!ruleForm.classifi || ruleForm.classifi.length == 0" class="el-dropdown-placeholder">请选择所属分类</span>
                                <span class="el-icon">
                                    <em v-show="!workOrderSelectIng" class="el-icon-arrow-down"></em>
                                    <em v-show="workOrderSelectIng" class="el-icon-arrow-up"></em>
                                </span>
                            </span>
                            <el-dropdown-menu slot="dropdown" id="work-order-dropdown-person">
                               <el-dropdown-item class="work-order-dropdown-item-person">
                                    <div style="max-height: 220px;overflow-y: auto;overflow-x:hidden">
                                        <el-tree
                                            :data="treeData"
                                            node-key="id"
                                            ref="relationTree"
                                            default-expand-all
                                            :highlight-current="true"
                                            :expand-on-click-node="true"
                                            check-strictly
                                            :props="treeDefaultProps">
                                            <span :class="['custom-tree-node']" slot-scope="{node,data}" @click.stop="onNodeClick(data,node)">
                                                <span class="custom-tree-node-left">
                                                    <span class="data-label">{{ data.taskTypeName }}</span>
                                                </span>
                                                <div>
                                                    <div class="isActive" v-if="data.checked">
                                                        <div class="sign"></div>
                                                    </div>
                                                    <div v-else class="isNotActive"></div>
                                                </div>
                                            </span>
                                        </el-tree>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-form-item>
                    <div class="local-document">
                        <div class="top">
                            <i class="iconfont guoran-tongyichicun-14_01-gongdanshuxing"></i>
                            <span>本地文档</span>
                        </div>
                        <div class="upload-remarks">
                            <div>1.支持txt、doc、docx、pdf格式的本地文档 </div>
                            <div>2.文档中的图片暂时无法识别，因此图片中的信息无法转换成意图与答案 </div>
                            <div>3.可上传多个文档 </div>
                        </div>
                        <el-upload
                            :class="['upload-demo',taskDetailObj.taskGroupStatus === 2 || taskDetailObj.taskGroupStatus === 4 || taskDetailObj.taskGroupStatus === 5 ? 'disabled' : '']"
                            drag
                            action="/api/oss"
                            multiple
                            :show-file-list="false"
                            accept=".doc,.docx,.txt,.pdf"
                            :disabled="taskDetailObj.taskGroupStatus === 2 || taskDetailObj.taskGroupStatus === 4 || taskDetailObj.taskGroupStatus === 5"
                            :on-error="onError"
                            :on-success="onSuccess"
                            :before-upload="beforeUpload">
                           <div class="upload-icon">
                                <i class="iconfont guoran-tongyichicun-shangchuan"></i>
                           </div>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        </el-upload>
                        <div :class="['file-list',taskDetailObj.taskGroupStatus === 2 || taskDetailObj.taskGroupStatus === 4 || taskDetailObj.taskGroupStatus === 5 ? 'disabled' : '']">
                            <div class="item" v-for="(item,index) in fileList" :key="index">
                                <img v-if="item.type === 'pdf'" src="../../../assets/images/document-pdf.png" alt="">
                                <img v-else-if="item.type === 'txt'" src="../../../assets/images/document-txt.png" alt="">
                                <img v-else-if="item.type === 'docx' || item.type === 'doc'" src="../../../assets/images/document-word.png" alt="">
                                <div class="one-column-ellipsis">{{item.name}}</div>
                                <i :class="['iconfont guoran-a-16_01',taskDetailObj.taskGroupStatus === 2 || taskDetailObj.taskGroupStatus === 4 || taskDetailObj.taskGroupStatus === 5? 'disabled' : '']" @click="delFile(item,index)"></i>
                            </div>
                        </div>
                    </div>
                </el-form>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round class="cancel-btn">取消</el-button>
                <el-button class="confirm-btn" type="primary" @click="submitForm('ruleForm')" :loading="saveBtnLoading" round style="padding:0 !important;">保存</el-button>
            </div>
        </popup>
   </div>
</template>

<script>
import Popup from '../../../components/popup.vue';
import { getFileSuffix } from "../../../utils/AliyunlssUtils";
export default {
    components:{Popup},
    props:{
        addTaskVisible:{
            type:Boolean,
            default:false
        },
        activeClassDetail:{
            type:Object,
            default(){
                return {}
            }
        },
        treeData:{
            type:Array,
            default(){
                return []
            }
        },
        apiKey:{
            type:String,
            default:""
        },
        taskDetail:{
            type:Object,
            default(){
                return {}
            }
        },
        isEdit:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            options: [],
            ruleForm:{
                name:'',
                classifi:[]
            },
            fileList: [],
            fileName:[],
            delFileList:[],
            oldFileList:[],
            taskDetailObj:{},
            addDocumentList:[],
            defaultCheckedkeys:[],
            treeDefaultProps:{
                children:'children',
                label:'taskTypeName',
                value:'id',
            },
            workOrderSelectIng:false,
            classifiName:'',
            saveBtnLoading:false, // 保存按钮的loading显隐
        }
    },
    watch:{
        addTaskVisible(n){
            if(n){
                this.addDocumentList = [];
                this.delFileList = [];
                this.fileName = [];
                this.fileList = [];
                this.ruleForm = {
                    name:'',
                    classifi:[]
                }
                if(this.isEdit){
                    this.ergodicCheckedValue(this.taskDetail.taskTypeId,this.treeData,this.isEdit)
                    this.ruleForm.classifi  = JSON.parse(this.taskDetail.taskTypeParent)
                    this.$forceUpdate()
                } else {
                    if(this.activeClassDetail.id){
                        console.log('-----------',this.activeClassDetail);
                        this.ergodicCheckedValue(this.activeClassDetail,this.treeData)
                        this.$forceUpdate()
                        // this.ruleForm.classifi = this.activeClassDetail.parentIds;
                    } else {
                        this.ruleForm.classifi  = [];
                    }
                }
               
                console.log(this.treeData,22);
                this.taskDetailObj = JSON.parse(JSON.stringify(this.taskDetail))
                if(this.taskDetailObj.taskGroupId){
                    this.ruleForm = {
                        name:this.taskDetailObj.taskName,
                        classifi:JSON.parse(this.taskDetail.taskTypeParent) || []
                    }
                    this.fileList = this.taskDetailObj.documentList;
                    this.oldFileList = JSON.parse(JSON.stringify(this.taskDetailObj.documentList));
                }
                console.log(this.ruleForm,'ruleForm');
            }
        }
    },
    methods:{
        elDropdownvisibleChange(val) {
            console.log(val,'val');
            this.workOrderSelectIng = val;
        },
        // 分类树结构选中事件
        onNodeClick(data,node){
            console.log(data,node);
            this.ergodicCheckedValue(data,this.treeData)
            this.$forceUpdate()
            console.log(this.treeData,11);
        },
        //遍历级联选中选项
        ergodicCheckedValue(data,treeData,isEdit){
            this.$nextTick(() => {
                treeData.forEach(item=>{
                    this.$set(item,'checked',false)
                    if(isEdit){
                        if(item.id == data){
                            console.log(item,444);
                            this.$set(item,'checked',true);
                            this.classifiName = item.taskTypeName;
                            this.ruleForm.classifi = [item.id];
                        }
                    } else {
                        if(data.id == item.id){
                            this.$set(item,'checked',true);
                        }
                        this.classifiName = data.parentNames;
                        this.ruleForm.classifi = data.parentIds || [];
                    }
                    if (item.children){
                        this.ergodicCheckedValue(data,item.children,isEdit)
                    }
                })
            })
        },
        delFile(item,index){
            if(this.taskDetailObj.taskGroupStatus === 2 || this.taskDetailObj.taskGroupStatus === 4 || this.taskDetailObj.taskGroupStatus === 5) return;
            if(item.documentId){
                this.delFileList.push({
                    type: item.type,
                    name:item.name,
                    fileOssPath:item.fileOssPath,
                    documentId:item.documentId
                });
            }
            this.fileList.splice(index,1);
        },
        onError(data){
            console.log(data,'失败');
            this.fileName = [];
        },
        handleChange(file, fileList) {
             console.log(this.fileList,222222);
        },
        onSuccess(data) {
            console.log(data,'成功');
            if(data.code === '0'){
                data.type = getFileSuffix(data.data,[],true)
                let name = ''
                this.fileName.forEach(item => {
                    if(data.data.indexOf(item) !== -1){
                        name = item;
                    }
                })
                this.fileList.push({
                    type: data.type,
                    name:name,
                    fileOssPath:data.data,
                    documentId:""
                });
                this.addDocumentList.push({
                    type: data.type,
                    name:name,
                    fileOssPath:data.data,
                });
            } else {
                this.fileName = [];
            }
        },
        beforeUpload(file){
            console.log(file,'file');
            let name = file.name.replace(/\s/g,"");
            name = name.replace('(','')
            name = name.replace(')','')
            this.fileName.push(name);
            if (file) {
                if (!getFileSuffix(file.name, ["txt", "docx",'doc', "pdf"])) {
                    this.$message.error("txt,docx,doc,pdf格式的文件");
                    return false;
                }
            }
        },
        closeEvent(){
            this.$emit('update:addTaskVisible',false)
        },

        // 保存
        submitForm(formName){
            let flag = true;
            if(this.ruleForm.name === ''){
                flag = false;
                this.$message.warning("请输入任务名字");
                return false;
            }
            if(this.fileList.length === 0){
                flag = false;
                this.$message.warning("请上传文档");
                return false;
            }
            if(!flag)return;
            this.saveBtnLoading = true;
            let obj = {
                documentIds:[],
                taskGroupId:null,
                classifi:this.ruleForm.classifi[this.ruleForm.classifi.length-1]
            }
            // 编辑
            if(this.taskDetailObj.taskGroupId){
                obj.taskGroupId = this.taskDetailObj.taskGroupId;
                let params = {
                    taskGroupId:this.taskDetailObj.taskGroupId,
                    taskTypeId: this.ruleForm.classifi[this.ruleForm.classifi.length-1],
                    taskGroupStatus:this.taskDetailObj.taskGroupStatus,
                    taskTypeParent:JSON.stringify(this.ruleForm.classifi)
                }
                if(this.taskDetail.taskName !== this.ruleForm.name){
                    params.updateTaskName = this.ruleForm.name;
                } else {
                    params.taskName = this.ruleForm.name;
                }
                if(this.delFileList.length > 0){
                    params.delDocumentList = this.delFileList;
                }
                if(this.addDocumentList.length > 0){
                    params.addDocumentList = this.addDocumentList;
                }
                this.fileList.forEach(item => {
                    if(item.documentId){
                        params.documentList = [];
                        params.documentList.push({
                            type: item.type,
                            name:item.name,
                            fileOssPath:item.fileOssPath,
                            documentId:item.documentId
                        });
                    } else {
                        // params.documentList = [];
                        // params.documentList.push({
                        //     type: item.type,
                        //     name:item.name,
                        //     fileOssPath:item.fileOssPath,
                        // });
                    }
                })
                // 任务构建中状态时 : 只有编辑了文档才会进行此校验
                // 编辑保存时校验是否依旧处于构建阶段，不处于构建阶段时，不允许保存并提示：任务已完成构建，不允许变更抽取文档
                // let isSameBoo = await this.equalsObj(this.oldFileList,this.fileList);
                // console.log(isSameBoo,'isSameBoo');
                // if(!isSameBoo){
                    this.FetchGet(this.requestUrl.documentExtract.selectTaskGroup+"?taskGroupId="+this.taskDetailObj.taskGroupId).then(res => {
                        if (res.code === "0") {
                            if(res.data.taskGroupStatus !== 1 && this.taskDetailObj.taskGroupStatus === 1){
                                this.$message({
                                    message: '任务已完成构建，不允许变更抽取文档',
                                    type: 'warning',
                                    duration:1000
                                });
                                this.saveBtnLoading = false;
                                // this.$emit('addTaskSuccess',obj,'edit');
                            } else {
                                // 调用编辑接口
                                res.data.documentIdAndOssPath && res.data.documentIdAndOssPath.forEach(item => {
                                    obj.documentIds.push(item.documentId);
                                })
                                 this.FetchPut(this.requestUrl.documentExtract.updateTask,'',params).then(res => {
                                    let obj = {
                                        documentIds:[],
                                        taskGroupId:res.data.taskGroupId
                                    }
                                    res.data.documentIdAndOssPath && res.data.documentIdAndOssPath.forEach(item => {
                                        obj.documentIds.push(item.documentId);
                                    })
                                    if (res.code === "0") {
                                        this.saveBtnLoading = false;
                                        this.$message({
                                            message: '编辑任务成功',
                                            type: 'success',
                                            duration:1000
                                        });
                                        this.$emit('addTaskSuccess',obj,'edit')
                                    } else {
                                        this.$message.error(res.message);
                                    }
                                });
                            }
                        } else {
                            this.saveBtnLoading = false;
                            this.$message.error(res.message);
                        }
                    });
                // } else {

                // }
            } else {
                // 新建    
                let params = {
                    documentList: this.fileList,
                    taskName: this.ruleForm.name,
                    taskTypeId: this.ruleForm.classifi[this.ruleForm.classifi.length-1],
                    taskTypeParent:JSON.stringify(this.ruleForm.classifi)
                }
                this.FetchPost(this.requestUrl.documentExtract.createTask,params).then(res => {
                    if (res.code === "0") {
                        this.saveBtnLoading = false;
                        this.$message({
                            message: '新建任务成功',
                            type: 'success',
                            duration:1000
                        });
                        obj.taskGroupId = res.data.taskGroupId;
                        res.data.documentIdAndOssPath && res.data.documentIdAndOssPath.forEach(item => {
                            obj.documentIds.push(item.documentId);
                        })
                        this.$emit('addTaskSuccess',obj,'add')
                    } else {
                        this.saveBtnLoading = false;
                        this.$message.error(res.message);
                    }
                });
            } 
        },
        /**
         * 判断此对象是否是Object类型
         * @param {Object} obj  
         */
        isObject(obj){
            return Object.prototype.toString.call(obj)==='[object Object]';
        },
        /**
         * 判断此类型是否是Array类型
         * @param {Array} arr 
         */
        isArray(arr){
            return Object.prototype.toString.call(arr)==='[object Array]';
        },
        /**
         *  深度比较两个对象是否相同
         * @param {Object} oldData 
         * @param {Object} newData 
         */
        equalsObj(oldData,newData){
            // 类型为基本类型时,如果相同,则返回true
            if(oldData === newData) return true;
            if(this.isObject(oldData)&&this.isObject(newData)&&Object.keys(oldData).length === Object.keys(newData).length){
                // 类型为对象并且元素个数相同
        
                // 遍历所有对象中所有属性,判断元素是否相同
                for (const key in oldData) {
                    if (oldData.hasOwnProperty(key)) {
                        if(!this.equalsObj(oldData[key],newData[key]))
                            // 对象中具有不相同属性 返回false
                            return false;
                    }
                }
            }else if(this.isArray(oldData)&&this.isArray(oldData)&&oldData.length===newData.length){
                // 类型为数组并且数组长度相同
        
                for (let i = 0,length=oldData.length; i <length; i++) {
                    if(!this.equalsObj(oldData[i],newData[i]))
                    // 如果数组元素中具有不相同元素,返回false
                    return false;
                }
            }else{
                // 其它类型,均返回false
                return false;
            }
            
            // 走到这里,说明数组或者对象中所有元素都相同,返回true
            return true;
        }
    }
}
</script>

<style scoped lang="less">
.el-popper{
    z-index: 6666!important;
    width: 525px;
    left: 478px;
    /deep/ .popper__arrow{
        left: 30px !important;
    }
    /deep/ .el-tree{
        .el-tree-node__content{
            line-height:  32px;
            height: 32px;
            .custom-tree-node{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .isNotActive{
                    flex: none;
                    width: 14px;
                    height: 14px;
                    background: white;
                    border: 1px solid #EBEDF4;
                    border-radius: 50%;
                    cursor: pointer;
                    margin-right: 20px;
                }
                .isActive{
                    flex: none;
                    width: 14px;
                    height: 14px;
                    background: #366AFF;
                    border: 1px solid #E4EAFF;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    margin-right: 20px;
                    .sign{
                        flex: none;
                        width: 4px;
                        height: 4px;
                        background: #fff;
                        border-radius: 50%;
                        
                    }
                }
            }
        }
    }
}
 /deep/.el-dropdown-menu__item:hover{
    background-color: #FFFFFF!important;
}
.add-task-popup{
    /deep/ .el-form{
        .el-form-item__label{
            float: left;
            padding-bottom: 10px;
            line-height: 20px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #616161;
        }
        .red{
            color: red;
        }
        .el-cascader{
            width: 100%;
        }
        .local-document{
            background: #FBFCFD;
            border-radius: 5px;
            padding: 15px;
            .top{
                height: 17px;
                display: flex;
                align-items: center;
                color: #616161;
                margin-bottom: 16px;
                i{
                    color: #366AFF;
                    margin-right: 6px;
                }
            }
            .upload-remarks{
                background: #FFF8F4;
                border: 1px solid #FFD9C5;
                border-radius: 5px;
                text-align: left;
                padding: 14px;
                margin-bottom: 10px;
                div{
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FF9555;
                    line-height: 20px;
                }
            }
            .upload-demo{
                width: 100%;
                .el-upload{
                    width: 100%;
                    background: #FFFFFF;
                    border: 1px solid #DBE4EF;
                    border-radius: 5px;
                    border: none;
                    .el-upload-dragger{
                        width: 100%;
                        height: 109px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        .upload-icon{
                            width: 37px;
                            height: 37px;
                            background: #EDF2FF;
                            border-radius: 50%;
                            color:#366AFF;
                            text-align: center;
                            line-height: 37px;
                            margin-bottom: 14px;
                        }
                    }
                }
                &.disabled{
                    .el-upload{
                        background: rgba(226, 226, 226, 0.2);
                    }
                    .el-upload-dragger{
                        cursor: no-drop;
                        background: rgba(226, 226, 226, 0.2);
                    }
                }
            }
            .file-list{
                margin-top: 20px;
                .item{
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    line-height: 14px;
                    margin-bottom: 10px;
                    div{
                        margin:0 10px 0 6px;
                        width: 448px;
                        text-align: left;
                    }
                    .iconfont{
                        cursor: pointer;
                        font-size: 16px;
                        color: #376BFF;
                        &.disabled{
                            cursor: not-allowed;
                        }
                    }
                }
                &.disabled{
                    cursor: not-allowed;
                    .iconfont{
                        color: #A9B3C6;
                    }
                }
            }
        }
        #el-dropdown-relation{
            width: 100%;
            min-height: 38px;
            overflow: hidden;
            .el-dropdown-link-workorder {
                display: flex;
                border: 1px solid #dcdfe6;
                border-radius: 5px;
                height: 38px;
                align-items: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
                /deep/.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
                    background-color: none !important;
                    line-height:  40px;
                }
                .el-dropdown-work-order-content {
                    flex: auto;
                    display: block;
                    height: 38px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 0 15px;
                    text-align: left;
                    padding-left: 15px;
                    font-size: 14px;
                    color: #606266;
                }
                .el-dropdown-placeholder {
                    flex: auto;
                    display: block;
                    height: 38px;
                    line-height: 38px;
                    padding: 0 15px;
                    color: #c0c4cc;
                    font-size: 14px;
                    text-align: left;
                }
                .el-icon {
                    flex: none;
                    width: 30px;
                    line-height: 38px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    em {
                        align-items: center;
                    }
                }
                
            }
        }
        
    }
}
</style>