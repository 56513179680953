<template>
    <div class="smart-document-extract">
        <page-top-header>
            <div slot="top-header-left" class="top-header-left">
                <i class="iconfont guoran-tongyichicun-zhinengwendangchouqu"></i>
                <span class="page-name">智能文档抽取</span>
            </div>
           
        </page-top-header>
       <div class="smart-document-extract-content">
            <div class="left-tree">
                <div class="no-data" v-if="treeData.length === 0">
                    <img src="../../assets/images/no-data.png" />
                    <!-- <div class="no-dat-tips">暂无分类数据</div> -->
                    <div class="add-classifi" @click="addClissifiFn">添加分类</div>
                </div>
                <div v-else class="left-content">
                    <el-tree
                        :data="treeData"
                        highlight-current
                        node-key="id"
                        :current-node-key="currentId"
                        :default-expand-all="true"
                        :expand-on-click-node="false"
                        @node-click="handleNodeClick"
                        ref="elTree"
                    >
                        <span class="el-tree-title" slot-scope="{ node, data }">
                            <span>
                                <span class="el-tree-title-word">
                                    <i
                                        style="color: #a1b9ff; margin-right: 5px"
                                        class="iconfont guoran-wenjianjia-zhankaizhuangtai--folderOpen"
                                    ></i>
                                </span>
                                <span class="el-tree-title-word-name one-column-ellipsis">{{
                                    data.taskTypeName
                                }}</span>
                            </span>
                            <span>
                                <span class="el-tree-title-num">{{
                                    data.taskNum ? data.taskNum : 0
                                }}</span>
                                <el-dropdown
                                    @command="handleCommand($event, node, data)"
                                    trigger="click">
                                    <span class="el-dropdown-link">
                                        <i class="arsenal_icon arsenalgengduo"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item :command="'addSameClass'">添加同级分类</el-dropdown-item>
                                        <el-dropdown-item :command="'addChildClass'">添加子分类</el-dropdown-item>
                                        <el-dropdown-item :command="'up'" :disabled="data.orderNum < 2">上移</el-dropdown-item>
                                        <el-dropdown-item :command="'down'" :disabled="data.orderNum === node.parent.childNodes.length">下移</el-dropdown-item>
                                        <el-dropdown-item :command="'edit'">编辑</el-dropdown-item>
                                        <el-dropdown-item :command="'del'">删除</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </span>
                        </span>
                    </el-tree>
                </div>
                <div :class="['my-task',isActiveMyTask ? 'active' : '']" @click="clickMyTask" v-if="treeData.length > 0">
                    <div class="left">
                        <i class="iconfont guoran-wenjianjia-zhankaizhuangtai--folderOpen"></i>
                        <span>我的任务</span>
                    </div>
                    <div class="num">{{myTaskNum}}</div>
                </div>
            </div>
            <div class="right-table-content">
                <div class="search-header">
                    <div class="left">
                        <i class="el-icon-delete" @click="deleteTask"></i>
                        <div class="line"></div>
                        <el-input
                            v-model="seacrhValue"
                            placeholder="搜索任务"
                            prefix-icon="el-icon-search"
                            @keyup.enter.native="seacrhTask"
                        ></el-input>
                    </div>
                    <el-button @click="addTask" type="primary" icon="el-icon-plus">新增任务</el-button>
                </div>
                <div class="task-content" id="sort-task-content" ref="taskListDom">
                    <template v-if="taskList.length > 0">
                        <div
                            @click.stop="opTaskDetail(item)"
                            :class="['item',item.taskStatus === 1 || item.taskStatus === 2 ? 'progress' : '',item.checked ? 'active-item' : '']"
                            v-for="item in taskList"
                            :key="item.id">
                            <div class="task-top">
                                <div class="task-name one-column-ellipsis">{{item.taskName}}</div>
                                <div @click.stop="checkedTaskCard(item)">
                                    <div class="isActive" v-if="item.checked">
                                        <div class="sign"></div>
                                    </div>
                                    <div v-else class="isNotActive"></div>
                                </div>
                            </div>
                            <div class="task-middle-detail">
                                <div class="num">
                                    <i class="iconfont guoran-tongyichicun-14_01-gongdanshuxing"></i>
                                    <div>文档数量</div>
                                    <span class="num-text">{{item.documentNum}}</span>
                                </div>
                                <div class="creat">
                                    <i class="guoran-tongyichicun-18-32-kehutijiao iconfont"></i>
                                    <div>创建人</div>
                                    <span class="creat-text one-column-ellipsis">
                                        <span>{{item.creatorName}}</span>
                                    </span>
                                </div>
                                <div class="creat-time">
                                    <i class="iconfont guoran-tongyichicun-12-09-shijianriqi"></i>
                                    <div>创建时间</div>
                                    <span class="time-text one-column-ellipsis">{{getDataTime(item.time)}}</span>
                                </div>
                            </div>
                            <div class="task-bottom">
                                <div :class="['left',item.taskStatus === 1 ? 'construction' : '',]" v-if="item.taskStatus === 1" >
                                    <img src="../../assets/images/building.png" alt="" />
                                    <span>任务构建中</span>
                                </div>
                                <div :class="['left', item.taskStatus === 2 ? 'middle' : '',]" v-else-if="item.taskStatus === 2">
                                    <img src="../../assets/images/extracting.png" alt=""/>
                                    <span>知识抽取中</span>
                                </div>
                                <div :class="['left', item.taskStatus === 3 ? 'extracted' : '',]" v-else-if="item.taskStatus === 3" >
                                    <i class="iconfont guoran-tongyichicun-shichang"></i>
                                    <span>待抽取</span>
                                </div>
                                <div :class="['left', item.taskStatus === 4 || item.taskStatus === 5 ? 'extracted-success': '', ]" v-else-if="item.taskStatus === 4 || item.taskStatus === 5">
                                    <i class="iconfont guoran-tongyichicun-kaiqi"></i>
                                    <span>{{item.taskStatus === 4 ? "抽取完成（待标注知识）": "抽取完成"}}</span>
                                </div>
                                <div :class="['left',item.taskStatus === 6 ? 'fail' : '',]" v-else-if="item.taskStatus === 6">
                                    <i class="el-icon-warning-outline"></i>
                                    <span>抽取失败</span>
                                </div>
                                <div class="right guoran-tongyichicun-dianjichakantuli iconfont" @click.stop="opTaskDetail(item)"></div>
                            </div>
                            <el-progress :percentage="item.taskProgress" :show-text="false" :stroke-width="5" v-if="item.taskStatus === 1 || item.taskStatus === 2"></el-progress>
                        </div>
                        <!-- <div v-for="(emptyCell, index) in needAddNumArr" :key="'emptyCell_' + index" class="item intent_empty"></div> -->
                    </template>
                    <div class="no-data" v-else>
                        <img src="../../assets/images/no-data.png" />
                        <div class="no-data-tips">暂无数据</div>
                    </div>
                </div>
                <div class="checked-all" v-if="taskList.length > 0">
                    <div @click.stop="checkedAllFn">
                        <div class="isActive" v-if="checkedAll">
                            <div class="sign"></div>
                        </div>
                        <div v-else class="isNotActive"></div>
                        <span>全选</span>
                    </div>
                </div>
            </div>
       </div>
        <popup @closeEvent="closeEvent" v-if="addClissifiPopup" class="add-classifi">
            <div slot="popup-name">{{addClassPopupName}}</div>
            <div slot="popup-con">
                <div class="popup-box">
                    <el-form
                        :model="ruleForm"
                        ref="ruleForms"
                        label-position="top"
                        label-width="80px"
                        class="demo-ruleForm"
                    >
                        <el-form-item label="名称" prop="name">
                            <span slot="label">名称<span class="red">*</span></span>
                            <el-input
                                v-model.trim="ruleForm.name"
                                maxlength="30"
                                placeholder="请输入名称"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round class="cancel-btn">取 消</el-button>
                <el-button class="confirm-btn" type="primary" @click="submitForm('ruleForm')" round style="padding:0 !important;">保存</el-button>
            </div>
        </popup>
        <addTaskPopup
            :apiKey="apiKey"
            :activeClassDetail="activeClassDetail"
            :treeData="treeData"
            :addTaskVisible.sync="addTaskVisible"
            :taskDetail="taskDetail"
            @addTaskSuccess="addTaskSuccess"></addTaskPopup>
        <!-- <taskStructure
            :drawerTaskStructure.sync="drawerTaskStructure"
            :taskStructureInfo="taskStructureInfo"></taskStructure> -->
      
        <taskDetail
            :drawerTaskDetail.sync="drawerTaskDetail"
            :taskDetail="taskDetail"
            :activeClassDetail="activeClassDetail"
            :treeData="treeData"
            @popupDelTask="popupDelTask"
            @closeDetail="closeDetail"
            @addTaskSuccess="addTaskSuccess"
            @extractingNow="extractingNow"
        ></taskDetail>
    </div>
</template>

<script>
import pageTopHeader from "../../components/page-top-header.vue";
import Popup from "../../components/popup.vue";
import addTaskPopup from "./components/addTaskPopup.vue";
import taskStructure from "./components/taskStructure.vue";
import taskDetail from "./components/taskDetail.vue";
import elementResizeDetectorMaker from "element-resize-detector";
export default {
    data() {
        return {
            treeData: [],
            activeTreeItemNode:{},
            activeClassDetail:{},
            currentId: null,
            defaultCheckedKeys: [],
            seacrhValue: "",
            checkedAll: false, // 是否全选
            taskList: [],
            addTaskVisible: false,
            drawerTaskStructure: false,
            drawerTaskDetail: false,
            addClissifiPopup: false,
            addClassPopupName:"添加分类",
            addClassPopupType:"addOneClass",
            myTaskList:[],
            isActiveMyTask:false,
            taskDetail:{},
            ruleForm:{
                name:''
            },
            myTaskNum:0,
            taskStructureInfo:{},
            progressTimer:null,
            taskProgessList:[],
            listLen:0,
            isMyTask:false
        };
    },
    components: {
        pageTopHeader,
        Popup,
        addTaskPopup,
        taskStructure,
        taskDetail,
    },
    props:{
        apiKey:{
            type:String,
            default:""
        }
    },
    watch:{
        taskList:{
            handler(newValue){
                this.$nextTick(() => {
                    this.listLen = newValue.length;
                    this.addEmptyEl(newValue.length);
                })
            },
            deep:true
        }
    },
    mounted(){
        this.getTree(true);
        this.getMyTask();
        this.$nextTick(() => {
            setTimeout(() => {
                // this.watchSize();
            }, 1000)
        })
        this.$eventBus.$on("test-window-visible", (visible) => {
            setTimeout(() => {
                // this.watchSize();
            }, 300)
        });
    },
    methods: {
        // 获取分类树结构数据
        getTree(flag){
            this.FetchGet(this.requestUrl.documentExtract.selectIntelligentExtractionTaskTypeList).then(res => {
                if (res.code === "0") {
                    this.treeData = this.handlerTree(res.data || []);
                    if(flag){
                        this.activeClassDetail = this.treeData[0] || {};
                        this.$nextTick(()=>{
                            if(this.treeData.length > 0){
                                this.currentId = this.treeData[0] ? this.treeData[0].id : '';
                                this.$refs.elTree.setCurrentKey(this.currentId);
                            }
                        })
                    } else {
                        this.$nextTick(()=>{
                            this.$refs.elTree.setCurrentKey(this.currentId);
                        })
                    }
                    if(this.treeData.length > 0){
                        console.log(this.activeClassDetail,'this.activeClassDetail');
                        if(this.activeClassDetail.id){
                            if(this.isMyTask){
                                this.getMyTask('search')
                            } else {
                                this.getTaskList();
                            }
                        } else {
                            // this.getMyTask();
                        }
                        this.treeData = this.addTreeAttribute(this.treeData);
                        console.log(this.treeData ,'this.treeData ');
                    } else {
                        this.taskList = [];
                    }
                } else {
                    this.$message.error(res.message);
                }
                // this.loading = false;
            });
        },
        handlerTree(treeData){
            treeData.forEach(item => {
                item.value = item.id;
                item.label = item.taskTypeName;
                item.parentIds = [];
                item.parentNames = [];
                item.checked = false;
                if(!item.children || item.children.length === 0){
                    item.children = null;
                } else {
                    this.handlerTree(item.children);
                }
            })
            return treeData
        },
         // 树节点增加属性
        addTreeAttribute(treeData,parentIds=[],parentNames=''){
            treeData.forEach(item => {
                if(item.parentId === -1){
                    item.parentIds = [item.id];
                    item.parentNames = item.taskTypeName;
                } else {
                    if(!item.parentIds || item.parentIds.length === 0){
                        item.parentIds = [...parentIds,...[item.id]];
                        item.parentNames += parentNames + '/' + item.taskTypeName;
                    } else {
                        item.parentIds = [...parentIds,...[item.id]]
                        item.parentNames += parentNames + '/' + item.taskTypeName;
                    }
                }
                if(!item.children || item.children.length === 0 ){
                    // item.parentNames = item.parentNames.substring(0,item.parentNames.length -1)
                } else {
                    this.addTreeAttribute(item.children,item.parentIds,item.parentNames);
                }
            })
            return treeData
        },
        // 获取任务列表
        getTaskList(){
            this.FetchGet(this.requestUrl.documentExtract.selectTaskByType+'?taskTypeId='+this.activeClassDetail.id+'&searchText='+this.seacrhValue).then(res => {
                if (res.code === "0") {
                    this.taskList = res.data || [];
                    this.handlerProgressTask();
                    // this.getTaskProgress();
                } else {
                    this.$message.error(res.message);
                }
                // this.loading = false;
            });
        },
        // 获取需要调用进度的任务列表
        handlerProgressTask(){
            this.taskProgessList = [];
            this.taskList.forEach(item => {
                if(item.taskStatus < 3){
                    this.taskProgessList.push({
                        taskGroupId:item.taskGroupId,
                        type:item.taskStatus
                    })
                } 
            })
            this.getTaskProgress();
        },
        // 获取任务进度
        getTaskProgress(){
            if(this.taskProgessList.length === 0) return;
            this.FetchPost(this.requestUrl.documentExtract.getProgressGroup,this.taskProgessList).then(res => {
                if (res.code === "0") {
                    this.taskProgessList = [];
                    res.data.forEach(v => {
                        if(v.taskGroupStatus < 3){
                            this.taskProgessList.push({
                                taskGroupId:v.taskGroupId,
                                type:v.taskGroupStatus
                            })
                        }
                    })
                    this.taskList.forEach(item => {
                        res.data.forEach(v => {
                            if(v.taskGroupId === item.taskGroupId){
                                item.taskProgress = v.progress.toFixed(2);
                                item.taskStatus = v.taskGroupStatus
                            }
                        })
                        if(item.taskStatus < 3){
                            
                        } else if(item.taskStatus === 3 || item.taskStatus === 6){
                            item.taskProgress = 0
                        } else if(item.taskStatus === 4 || item.taskStatus === 5){
                            item.taskProgress = 100
                        }
                    })
                   console.log(this.taskList,'this.taskList');
                    this.$forceUpdate();
                    if(this.taskProgessList.length > 0){
                        clearInterval(this.progressTimer)
                        this.progressTimer = setInterval(() => {
                            this.getTaskProgress();
                        },2000) 
                    } else {
                        clearInterval(this.progressTimer)
                    }
                } else {
                    clearInterval(this.progressTimer)
                    this.$message.error(res.message);
                }
            });
        },
        // 时间戳转换成时间
		getDataTime(times){
			if (!times) {
				return '-'
			}
			let getBYT = (num) => {
				if(num == 0) {
					return '00'
				} else if(num < 10) {
					return '0' + num;
				} else {
					return num;
				}
			}
			let date = new Date(times); // 13位时间戳
			let Y = date.getFullYear() + '-';
			let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
			let D = getBYT(date.getDate())+ ' ';
            let H = getBYT(date.getHours())+ ':';
            let F = getBYT(date.getMinutes());
			return Y+M+D+H+F;
		},
        addClissifiFn() {
            this.addClassPopupName = '添加分类';
            this.addClassPopupType = 'addOneClass';
            this.addClissifiPopup = true;
        },
        // 点击tree节点
        handleNodeClick(data,node,el) {
            console.log(data,node,el,'点击tree节点');
            this.isMyTask = false;
            this.isActiveMyTask = false;
            this.currentId = data.id;
            this.activeTreeItemNode = node;
            this.activeClassDetail = data;
            this.checkedAll = false;
            this.seacrhValue = '';
            this.getTaskList();
            this.getMyTask();
        },
        // 点击下拉操作列表
		handleCommand(type,node,data){
            this.isMyTask = false;
            console.log(type,node,data,'点击下拉操作列表');
            this.currentId = data.id;
            this.isActiveMyTask = false;
            this.addClassPopupType = type;
            this.ruleForm.name = '';
            this.checkedTreeItem = data;
            if(type === 'addSameClass'){
                this.addClassPopupName = '添加同级分类';
                this.addClissifiPopup = true;
            } else  if(type === 'addChildClass'){
                this.addClassPopupName = '添加子分类';
                this.addClissifiPopup = true;
            } else  if(type === 'up' || type === 'down'){
                let currentData = JSON.parse(JSON.stringify(data));
                let params = [];
                let list = node.parent.childNodes;
                list.forEach((item,index) => {
                    if(currentData.id === item.data.id){
                        if(type === 'up'){
                            currentData.orderNum  = currentData.orderNum-1 < 1 ? 1 : currentData.orderNum-1;
                            list[index-1].data.orderNum += 1;
                            params = [...[currentData],...[list[index-1].data]];
                        } else {
                            currentData.orderNum += 1;
                            list[index+1].data.orderNum =  list[index+1].data.orderNum-1 < 1 ? 1 :  list[index+1].data.orderNum-1;
                            params = [...[currentData],...[list[index+1].data]];
                        }
                    }
                })
                this.FetchPut(this.requestUrl.documentExtract.moveLocation,'',params)
                    .then((res) => {
                        if(res.code == 0){
                            this.$message.success(type === 'up' ? '上移成功' : '下移成功')
                            this.getTree(false);
                        } else {
                            this.$message.error(res.message)
                        }
                    });
                
            } else  if(type === 'edit'){
                this.addClassPopupName = '编辑分类';
                this.ruleForm.name = this.activeClassDetail.taskTypeName;
                this.addClissifiPopup = true;
            } else  if(type === 'del'){
                this.$confirm('此操作将永久删除选中的分类, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.FetchDelete(this.requestUrl.documentExtract.delIntelligentExtractionTaskType,this.activeClassDetail.id)
                        .then(res=>{
                            if (res.code === '0'){
                                this.$message({
                                    message:'删除成功',
                                    type:'success',
                                    duration:1000
                                })
                                this.getTree(true);
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });

            }
        },
        handelOrderNum(){
            let num = 0;
            if(this.addClassPopupType === 'addSameClass'){
                num = this.activeTreeItemNode.parent.childNodes.length + 1;
            } else if (this.addClassPopupType === 'addChildClass'){
                if(this.activeTreeItemNode.childNodes.length === 0){
                    num = 1;
                } else {
                    num = this.checkedTreeItem.children[this.checkedTreeItem.children.length-1].orderNum + 1;
                }
            }
           return num;
        },
        // 添加分类
        submitForm(){
            if(this.ruleForm.name === ''){
                this.$message.warning("请输入分类名字");
            } else {
                if(this.addClassPopupType === 'edit'){
                    this.FetchGet(this.requestUrl.documentExtract.updateIntelligentExtractionTaskType+'?id='+this.activeClassDetail.id + '&name='+this.ruleForm.name).then(res => {
                        if (res.code === "0") {
                            this.$message({
                                message: '编辑成功',
                                type: 'success',
                                duration:1000
                            });
                            this.addClissifiPopup = false;
                            this.getTree(false);
                        } else {
                            this.$message.error(res.message);
                        }
                        // this.loading = false;
                    });
                } else {
                    let params = {
                        parentId: -1,
                        taskTypeName: this.ruleForm.name,
                        orderNum:1,
                    }
                    if(this.addClassPopupType === 'addOneClass'){
                       params.parentId = -1;
                       params.orderNum = 1;
                    } else  if(this.addClassPopupType === 'addSameClass') {
                        params.parentId = this.activeClassDetail.parentId;
                        params.orderNum = this.handelOrderNum();
                    } else  if(this.addClassPopupType === 'addChildClass') {
                        params.parentId = this.activeClassDetail.id;
                        params.orderNum = this.handelOrderNum();
                    } 
                    this.FetchPost(this.requestUrl.documentExtract.addIntelligentExtractionTaskType,params).then(res => {
                        if (res.code === "0") {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration:1000
                            });
                            this.addClissifiPopup = false;
                            this.getTree(this.addClassPopupType === 'addOneClass' ? true : false);
                        } else {
                            this.$message.error(res.message);
                        }
                        // this.loading = false;
                    });
                }
            }
        },
        closeEvent() {
            this.addClissifiPopup = false;
        },
        clickMyTask(){
            this.isMyTask = true;
            this.activeClassDetail = this.treeData[0];
            this.currentId = null;
            this.$refs.elTree.setCurrentKey(null);
            this.isActiveMyTask = true;
            this.checkedAll = false;
            this.seacrhValue = '';
            this.taskList = this.myTaskList;
            this.handlerProgressTask();
            // this.getTaskProgress();
        },
        // 搜索任务
        seacrhTask(){
            if(this.currentId && this.currentId !== ''){
                this.getTaskList();
            } else {
                this.getMyTask('search');
            }
        },
        // 获取我的任务
        getMyTask(type){
            this.FetchGet(this.requestUrl.documentExtract.selectTaskByMyself+'?searchText='+this.seacrhValue).then(res => {
                if (res.code === "0") {
                    this.myTaskList = res.data || [];
                    this.myTaskList.forEach((item,index) => {
                        item.checked = false;
                    })
                    if(type && type === 'search'){
                        this.taskList = this.myTaskList;
                    } else {
                        this.myTaskNum = res.data.length;
                    }
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        // 删除任务
        deleteTask(type,ids) {
            let checkedList = [];
            if(type && type === 'popupDelTask'){
                checkedList = ids;
            } else {
                this.taskList.forEach((item,index) => {
                    if(item.checked){
                        checkedList.push(item.taskGroupId);
                    }
                })
            }
            if(checkedList.length > 0){
                this.$confirm(type && type === 'popupDelTask' ? '此操作将永久删除该任务, 是否继续?' : '此操作将永久删除选中的任务, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.FetchPost(this.requestUrl.documentExtract.delTask,checkedList)
                        .then(res=>{
                            if (res.code === '0'){
                                this.$message({
                                    message:'删除成功',
                                    type:'success',
                                    duration:1000
                                })
                                this.getTree(false);
                                this.getMyTask();
                                if(type && type === 'popupDelTask'){
                                    this.drawerTaskDetail = false;
                                }
                            } else {
                                this.$message.error(res.message)
                            }
                        })
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            } else {
                this.$message.warning('请先选择要删除的任务');
            }
        },
        // 新增任务
        addTask() {
            if(this.treeData.length === 0){
                this.$message.warning('请先添加分类');
                return;
            } else {
                console.log(this.activeClassDetail,444);
                this.taskDetail = {};
                this.addTaskVisible = true;
            }
        },
        // 选中某个任务卡片
        checkedTaskCard(item) {
            this.$set(item,'checked',!item.checked);
            // every 
            // 检测数组里的每一个值是否满足指定条件，如果有一个值不满足，返回false，剩余的值不再进行检测
            //   如果所有的值都满足，则返回true
            let flag = this.taskList.every((item,index) => {
                return item.checked; 
            })
            this.checkedAll = flag;
            this.$forceUpdate();
            
        },
        // 全选
        checkedAllFn() {
            this.checkedAll = !this.checkedAll;
            this.taskList.forEach((item,index) => {
                item.checked = this.checkedAll;
            })
        },
       
          // 获取任务详情
        getTaskDetail(taskGroupId = '',isAddTask){ 
            this.FetchGet(this.requestUrl.documentExtract.selectTaskGroup+"?taskGroupId="+taskGroupId).then(res => {
                if (res.code === "0") {
                    this.taskDetail  = res.data || {};
                    this.taskDetail.documentIds = [];
                    this.taskDetail.taskGroupId = taskGroupId;
                    // this.taskDetail.taskGroupStatus = 1;  
                    this.taskDetail.documentList && res.data.documentList.forEach(item => {
                        this.taskDetail.documentIds.push(item.documentId);
                        item.progress = 0;
                    })
                    console.log(this.taskDetail,'详情');
                    if(isAddTask){
                        if(this.taskDetail.taskGroupStatus === 1){
                            this.drawerTaskDetail = true;
                        } else {
                            this.drawerTaskDetail = false;
                        }
                    } else {
                        this.drawerTaskDetail = true;
                    }
                } else {
                    this.$message.error(res.message);
                }
                // this.loading = false;
            });
        },
        // 打开任务详情
        opTaskDetail(item){
            // if(item.taskStatus === 6) return;
            this.getTaskDetail(item.taskGroupId,false);
        },
        // 任务详情中点击编辑按钮后
        editTask(data){
            this.addTaskVisible = true;
            this.taskDetail = data;
        },
        // 任务详情中 , 删除
        popupDelTask(taskGroupId){
            this.deleteTask('popupDelTask',[taskGroupId])
        },
        // 添加任务成功
        addTaskSuccess(data,type) {
            this.getTree(false);
            this.getMyTask();
            this.addTaskVisible = false;
            if(type === 'edit'){
                this.getTaskDetail(data.taskGroupId,false);
            } else {
                this.getTaskDetail(data.taskGroupId,true);
            }
            this.$nextTick(() => {
                if(this.treeData.length > 0){
                    this.$refs.elTree.setCurrentKey(data.classifi);
                }
            })
        },
        stopTask(){
            // this.getTree(false);
            // this.getMyTask();
        },
        extractingNow(){
            this.getTree(false);
            this.getMyTask();
        },
        // 关闭任务详情弹框
        closeDetail(){
            // this.getTree(false);
            // this.getMyTask();
            this.drawerTaskDetail = false;
        },
        // 动态卡片宽度处理
        watchSize() {
            let _this = this;
            let erd = elementResizeDetectorMaker();
            if (this.$refs.taskListDom != undefined) {
                erd.listenTo(this.$refs.taskListDom, (element) => {
                    let height = element.offsetHeight;
                    _this.$nextTick(() => {
                        // 这里填写监听改变后的操作
                        // console.log(height, "+_+_+_+_+_+");
                        // this.reduceHeight = height;
                        if (_this.listLen !== 0) {
                            _this.addEmptyEl(_this.listLen);
                        }
                    })
                });
            }
            
        },
        addEmptyEl(listLen){
            // console.log(this.$refs.intentListContent.offsetWidth, this.$refs.intentListContent.offsetHeight, listLen);
            if (this.$refs.taskListDom != undefined) {
                let outerW = this.$refs.taskListDom.offsetWidth; // 容器宽度
                let cellW = 339; // 每个cell宽度
                // console.log(outerW, cellW);
                // console.log(Math.floor(outerW/cellW)) 
                let evNum = Math.floor(outerW/cellW); // 每行个数
                let needAddNum = (evNum - ((listLen  + 1)%evNum)) === evNum?0:(evNum - ((listLen  + 1)%evNum)); // 最后一行需要补空意图数
                // console.log(needAddNum)
                this.needAddNum = needAddNum;
                this.needAddNumArr = "11111111111".substring(0,needAddNum).split("");
            }
            
            // console.log(this.needAddNumArr)
        },
    },
    beforeDestroy(){
        clearInterval(this.progressTimer)
    }
};
</script>

<style lang="less" scoped>
@import './../../assets/less/main/common.less';
@import "./../../assets/less/smartDocumentExtract/smartDocumentExtract.less";
</style>