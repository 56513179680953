<!--任务详情-->
<template>
    <div class="task-detail" v-if="drawerTaskDetail">
        <el-drawer
            :class="[taskStructureing ? 'task-structure-content' : 'task-detail-content']"
            key="intent-task-structure"
            :title="title"
            :size="'1200px'"
            show-close
            direction="rtl"
            :visible.sync="drawerTaskDetail"
            :close-on-press-escape="false"
            :destroy-on-close="true"
            :wrapperClosable="false"
            :with-header="true">
            <!-- 任务构建中 -->
            <div v-if="taskStructureing" class="task-structure" v-loading="loading">
                <div class="notice-drewer-header">
                    <img src="../../../assets/images/building.png" alt="">
                    <div class="title">任务构建中</div>
                    <div class="header-detail">
                        <div class="task-name one-column-ellipsis">{{taskDetailObj.taskName}}</div>
                        <el-button round type="primary" icon="iconfont guoran-a-16-15" @click="editTask" class="edit">编辑</el-button>
                        <el-button round icon="iconfont guoran-a-18-13" @click="delTask" class="del">删除</el-button>
                    </div>
                    <div class="close">
                        <span @click="handleCloseTaskStructure" class="el-icon-close"></span>
                    </div>
                </div>
                <div class="task-structure-content">
                    <div class="task-structure-list">
                        <div class="task-structure-item" v-for="(item,index) in taskDetailObj.documentList" :key="item.documentId">
                            <div class="progress-100" v-if="item.progress == 100">
                                <img v-if="item.type === 'pdf'" src="../../../assets/images/document-pdf.png" alt="">
                                <img v-else-if="item.type === 'txt'" src="../../../assets/images/document-txt.png" alt="">
                                <img v-else-if="item.type === 'docx' || item.type === 'doc'" src="../../../assets/images/document-word.png" alt="">
                                <span class="file-name one-column-ellipsis">{{item.name}}</span>
                                <i class="el-icon-success"></i>
                            </div>
                            <div class="progress-box" v-else>
                                <div class="top-box">
                                    <!-- <img v-if="item.type === 'xlsx'" src="../../../assets/images/document-excl.png" alt=""> -->
                                    <img v-if="item.type === 'pdf'" src="../../../assets/images/document-pdf.png" alt="">
                                    <img v-else-if="item.type === 'txt'" src="../../../assets/images/document-txt.png" alt="">
                                    <img v-else-if="item.type === 'docx' || item.type === 'doc'" src="../../../assets/images/document-word.png" alt="">
                                    <span class="file-name one-column-ellipsis">{{item.name}}</span>
                                </div>
                                <el-progress :percentage="item.progress" :stroke-width="3"></el-progress>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-time">
                        预计<span>{{surplusTime}}</span>分钟后进行知识抽取
                    </div>
                </div>
            </div>
            <!-- 详情 -->
           <div class="task-detail-content-container" v-else v-loading="loading">
                <div class="notice-drewer-header">
                    <i class="iconfont guoran-tongyichicun-shichang" v-if="taskDetailObj.taskGroupStatus === 3" ></i>
                    <i class="iconfont guoran-tongyichicun-kaiqi" v-if="taskDetailObj.taskGroupStatus === 4 || taskDetailObj.taskGroupStatus === 5" ></i>
                    <img src="../../../assets/images/extracting.png" alt="" v-if="taskDetailObj.taskGroupStatus === 2" />
                    <i class="el-icon-warning-outline" v-if="taskDetailObj.taskGroupStatus === 6" ></i>
                    <div class="title">{{ title }}</div>
                    <div class="now-extract" v-if="taskDetailObj.taskGroupStatus === 3" @click="nowExtract">立即抽取</div>
                    <div class="header-detail">
                        <div class="task-name one-column-ellipsis">{{taskDetailObj.taskName}}</div>
                        <el-button round type="primary" icon="iconfont guoran-a-16-15" @click="editTask" class="edit">编辑</el-button>
                        <el-button round icon="iconfont guoran-a-18-13" @click="delTask" class="del">删除</el-button>
                        <el-button round icon="iconfont guoran-tongyichicun-kaishizhihang" @click="stopTask" v-if="taskDetailObj.taskGroupStatus === 2" class="stop-task">暂停</el-button>
                    </div>
                    <div class="close">
                        <span @click="handleCloseTaskStructure" class="el-icon-close"></span>
                    </div>
                </div>
                <div :class="['task-detail-content', taskDetailObj.taskGroupStatus === 2 || taskDetailObj.taskGroupStatus === 3 ? 'no-extract' : '',]" >
                    <div class="task-structure-list" v-if="taskDetailObj.taskGroupStatus === 2 || taskDetailObj.taskGroupStatus === 3">
                        <div :class="['task-structure-item',activeDocumentId === item.documentId ? 'active' : '']" v-for="(item,index) in taskDetailObj.documentList" :key="item.documentId" @click="switchDocument(item,index)">
                            <div class="progress-100" v-if="item.progress == 100">
                                <!-- <img v-if="item.type === 'xlsx'" src="../../../assets/images/document-excl.png" alt=""> -->
                                <img v-if="item.type === 'pdf'" src="../../../assets/images/document-pdf.png" alt="">
                                <img v-else-if="item.type === 'txt'" src="../../../assets/images/document-txt.png" alt="">
                                <img v-else-if="item.type === 'docx' || item.type === 'doc'" src="../../../assets/images/document-word.png" alt="">
                                <span class="file-name one-column-ellipsis">{{item.name}}</span>
                                <i class="el-icon-success"></i>
                            </div>
                            <div class="progress-box" v-else>
                                <div class="top-box">
                                    <!-- <img v-if="item.type === 'xlsx'" src="../../../assets/images/document-excl.png" alt=""> -->
                                    <img v-if="item.type === 'pdf'" src="../../../assets/images/document-pdf.png" alt="">
                                    <img v-else-if="item.type === 'txt'" src="../../../assets/images/document-txt.png" alt="">
                                    <img v-else-if="item.type === 'docx' || item.type === 'doc'" src="../../../assets/images/document-word.png" alt="">
                                    <span class="file-name one-column-ellipsis">{{item.name}}</span>
                                </div>
                                <el-progress :percentage="item.progress" :stroke-width="3"></el-progress>
                            </div>
                        </div>
                    </div>
                    <!-- 抽取结果 -->
                    <div v-else style="width:50%">
                        <div class="remark-box">
                            <div class="remark-box-top">
                                <div class="left-tabs">
                                    <div :class="['no-remark',activeName === 'no-remark'? 'active': '',]" @click="switchTab('no-remark')">
                                        <span>未标注</span>
                                        <div :class="['num',markContent.noMarkedNum > 9 ? 'big':'']">{{markContent.noMarkedNum || 0}}</div>
                                    </div>
                                    <div  :class="['remarked',activeName === 'remarked'? 'active': '',]" @click="switchTab('remarked')">
                                        <span>已标注</span>
                                        <div :class="['num',markContent.markedNum > 9 ? 'big':'']">{{markContent.markedNum || 0}}</div>
                                    </div>
                                </div>
                                <el-select v-model="activeDocumentId" placeholder="请选择" @change="changeFile">
                                    <el-option
                                        v-for="item in options"
                                        :key="item.documentId"
                                        :label="item.name"
                                        :value="item.documentId">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="remark-box-container">
                                <div class="no-data" v-if="extractList.length === 0">
                                    <img src="../../../assets/images/no-extract-result.png" alt=""/>
                                    <div class="text">暂无抽取结果</div>
                                    <el-button round size="middle" type="primary" v-if="taskDetailObj.taskGroupStatus === 6" @click="nowExtract">重新抽取</el-button>
                                </div>
                                <div v-else class="have-content">
                                    <div class="warn-tips-box">
                                        <div class="warn-tips">系统将自动过滤抽取结果完全一致的问题</div>
                                    </div>
                                    <div class="scroll-box">
                                        <div v-for="(item,index) in extractList" :key="item.id" :class="['scroll-box-item'+item.id,'scroll-box-item']">
                                            <div class="intent-name one-column-ellipsis" v-if="activeName === 'remarked'">
                                                <div>
                                                    <div class="bot-name">{{item.botName}}</div>
                                                    <div>{{item.intentName}}</div>
                                                </div>
                                                <i class="iconfont guoran-tongyichicun-youjiantou" @click="openIntentWeb(item,index)"></i>
                                            </div>
                                            <div class="extract-qa">
                                                <i class="iconfont guoran-tongyichicun-wenti"></i>
                                                <div class="qa-box">{{item.question}}</div>
                                            </div>
                                            <div class="extract-answer-box">
                                                <div class="extract-answer">
                                                    <i class="iconfont guoran-tongyichicun-daan"></i>
                                                    <div class="answer-box" v-html="item.answer.replace(/\n|\r\n/g,'<br/>')"></div>
                                                </div>
                                                <div class="location-icon">
                                                    <el-button round  icon="el-icon-circle-plus-outline" v-if="activeName === 'no-remark'" @click="addExtractResult(item,index)">标注</el-button>
                                                    <el-button round icon="el-icon-location-outline"  @click="goLocation(item,index)" class="location">定位</el-button>
                                                </div>
                                            </div>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right-html-content" v-loading="htmlLoading">
                        <div class="hidden-text" v-html="this.allHtmlText.text"></div>
                        <div class="file-name" v-if="taskDetailObj.taskGroupStatus === 4 || taskDetailObj.taskGroupStatus === 5 || taskDetailObj.taskGroupStatus === 6">
                            <!-- <img v-if="type === 'xlsx'" src="../../../assets/images/document-excl.png" alt=""> -->
                            <img v-if="type === 'pdf'" src="../../../assets/images/document-pdf.png" alt="">
                            <img v-else-if="type === 'txt'" src="../../../assets/images/document-txt.png" alt="">
                            <img v-else-if="type === 'docx' || item.type === 'doc'" src="../../../assets/images/document-word.png" alt="">
                            <span class="one-column-ellipsis">{{fileName}}</span>
                        </div>
                        <div :class="['right-html-content-detail',activeDocumentObj.type === 'docx' ? 'docx-document' : '']">
                            <div v-html="htmlContent"></div>
                        </div>
                    </div>
                </div>
                <div class="bottom-time" v-if="taskDetailObj.taskGroupStatus === 2">
                    预计<span>{{surplusTime}}</span>分钟后完成知识抽取
                </div>
            </div>
        </el-drawer>
          <addExtractResult
            :addExtractResultVisible.sync="addExtractResultVisible"
            :activeMarkItem="activeMarkItem"
            :taskDetailObj="taskDetailObj"
            @addExtractingResultSuccess="addExtractingResultSuccess"
        ></addExtractResult>
        <addTaskPopup
            :apiKey="apiKey"
            :activeClassDetail="activeClassDetail"
            :treeData="treeData"
            :addTaskVisible.sync="addTaskVisible"
            :taskDetail="taskDetailObj"
            :isEdit="true"
            @addTaskSuccess="addTaskSuccess"></addTaskPopup>
    </div>
</template>

<script>
import addTaskPopup from "./addTaskPopup.vue";
import addExtractResult from "./addExtractResult.vue";
import { rediect } from "@/const/rediect";
export default {
    components:{addExtractResult,addTaskPopup},
    props: {
        drawerTaskDetail: {
            type: Boolean,
            default: false,
        },
        status: {
            type: Number,
            default: 1, // 1 待抽取  2 抽取中 3抽取完成
        },
        drawerTaskStructure:{
			type:Boolean,
			default:false
		},
        taskDetail:{
            type:Object,
            default(){
                return {}
            }
        },
        activeClassDetail:{
            type:Object,
            default(){
                return {}
            }
        },
        treeData:{
            type:Array,
            default(){
                return []
            }
        },
    },
    data() {
        return {
            size: "690px",
            activeName: "no-remark",
            activeDocumentId: "all",
            activeDocumentObj:{},
            options: [],
            type: "pdf",
            taskStructureing:false,
            taskDetailObj:{},
            loading:true,
            dialogUrl:'',
            surplusTime:0,
            htmlContent:"",
            markContent:{},
            extractList:[],
            addExtractResultVisible:false,
            activeMarkItem:{},
            fileName:"",
            getExtractResultType:"all",
            activeEl:null,
            allData:[],
            addTaskVisible:false,
            allHtmlText:{},
            previewImageUrl:'',
            htmlLoading:false,
            htmlDocumentId:""
        };
    },
    watch: {
        drawerTaskDetail(n){
            clearTimeout(this.timer)
            if(n){
               this.htmlContent = '';
               this.extractList = [];
            } else {
                clearTimeout(this.timer)
            }
        },
        taskDetail:{
            handler(n){
                if(this.drawerTaskDetail){
                    clearTimeout(this.timer)
                    this.loading = true;
                    this.htmlLoading = false
                    this.activeName = 'no-remark'
                    this.taskDetailObj = JSON.parse(JSON.stringify(this.taskDetail));
                    this.options = [...[{
                        documentId:"all",
                        name:'全部文档'
                    }],...this.taskDetailObj.documentList]
                    this.surplusTime = 0;
                    if(this.taskDetailObj.taskGroupStatus === 2 || this.taskDetailObj.taskGroupStatus === 3){
                        this.activeDocumentId = this.taskDetailObj.documentList[0].documentId;
                        this.activeDocumentObj = this.taskDetailObj.documentList[0];
                    } else {
                        this.activeDocumentId = 'all';
                        this.activeDocumentObj = {}
                    }
                    this.handlerTitle(this.taskDetail,true);
                    window.clickSpanLocation = this.clickSpanLocation;
                } else {
                    clearTimeout(this.timer)
                }
            },
            deep:true
        }
    },
    methods: {
        // 抽取中 : 切换文档
        switchDocument(item,index){
            this.activeDocumentId = item.documentId;
            this.activeDocumentObj = item;
            this.getDowumentHtml(item.documentId);
        },
        addTaskSuccess(data,type){
            this.$emit('addTaskSuccess',data,type)
            this.getTaskDetail(this.taskDetailObj.taskGroupId);
            this.addTaskVisible = false;
           
        },
        // 获取进度
        getProgress(type){
            // 构建进度 type=1  抽取进度type=2
            console.log(this.taskDetail,'====');
            let documentIds = []
            this.taskDetailObj.documentList && this.taskDetailObj.documentList.forEach(item => {
                documentIds.push(item.documentId);
            })
            this.FetchPost(this.requestUrl.documentExtract.getProgress+'?type='+type,documentIds).then(res => {
                if (res.code === "0") {
                    let flag = true;
                    console.log(res.data.documentProgressList,333,this.taskDetailObj.documentList);
                    res.data.documentProgressList.forEach(item => {
                        if(!item.progress || Number(item.progress) < 100){
                            flag = false;
                        } 
                        this.taskDetailObj.documentList.forEach(v => {
                            if(item.documentId == v.documentId){
                                console.log(v);
                                v.progress = item.progress;
                                // this.$set(v,'progress',item.progress)
                            }
                           
                        })
                    })
                    this.surplusTime = res.data.surplusTime;
                    console.log(this.taskDetailObj,'处理后的详情----');
                    this.loading = false;
                    this.$forceUpdate();
                    if(flag){
                        clearTimeout(this.timer);
                        if(this.taskDetailObj.taskGroupStatus === 2){
                            this.getTaskDetail(this.taskDetailObj.taskGroupId,'progress');
                        }
                        // setTimeout(() => {
                        //     this.getTaskDetail(this.taskDetail.taskGroupId,'progress');
                        // },5000)
                    } else {
                        this.timer = setTimeout(() => { 
                            this.getProgress(type);
                        },2000)
                    }
                } else {
                    clearInterval(this.timer)
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        },
        // 获取任务详情
        getTaskDetail(taskGroupId = '',type){ 
            clearTimeout(this.timer);
            // this.taskDetailObj.documentIds = [];
            this.loading = true;
            this.FetchGet(this.requestUrl.documentExtract.selectTaskGroup+"?taskGroupId="+taskGroupId).then(res => {
                if (res.code === "0") {
                    this.taskDetailObj  = res.data || {};
                    this.taskDetailObj.taskGroupId = taskGroupId;
                   
                    console.log(res.data.documentList,'res.data.documentList');
                    // res.data.documentList && res.data.documentList.forEach(item => {
                    //     this.taskDetailObj.documentIds.push(item.documentId);
                    //     // if(type && type === 'progress'){
                    //     //     item.progress = 100;
                    //     // }
                    // })
                   
                    // console.log(this.taskDetail.documentIds,'9999');
                    this.handlerTitle(this.taskDetailObj,true)
                } else {
                    this.$message.error(res.message);
                }
                // this.loading = false;
            });
        },
        handlerTitle(taskDetail,flag){
            this.loading = true;
            if(taskDetail.taskGroupStatus === 1 || taskDetail.taskGroupStatus === 2 || taskDetail.taskGroupStatus === 3){
                if(taskDetail.taskGroupStatus === 1 ){
                    this.taskStructureing = true;
                    this.title = '任务构建中';
                    this.getProgress(1);
                } else if(taskDetail.taskGroupStatus === 2 ){
                    this.title = '知识抽取中';
                    this.taskStructureing = false;
                    this.getProgress(2);
                    this.activeDocumentObj = this.taskDetail.documentList[0];
                    this.getDowumentHtml(this.taskDetail.documentList[0].documentId);
                } else {
                    this.title = '待抽取';
                    this.taskDetail.documentList.forEach(v => {
                        v.progress = 0;
                    })
                    this.taskStructureing = false;
                    // this.loading = false;
                    this.activeDocumentObj = this.taskDetail.documentList[0];
                    this.getDowumentHtml(this.taskDetail.documentList[0].documentId);
                    clearTimeout(this.timer);
                }
            } else if (taskDetail.taskGroupStatus === 6){
                this.taskStructureing = false;
                this.title = '抽取失败';
                this.getExtractResultType = 'all';
                this.activeDocumentId = 'all';
                this.activeDocumentObj = {};
                this.getExtractResult('all','');
                this.fileName = this.taskDetail.documentList[0].name;
            } else {
                this.taskStructureing = false;
                this.title = '抽取结果';
                this.getExtractResultType = 'all';
                this.activeDocumentId = 'all';
                this.activeDocumentObj = {};
                this.getExtractResult('all','');
                this.fileName = this.taskDetail.documentList[0].name;
            }
            this.$forceUpdate();
        },
        // 获取文档抽取结果
        getExtractResult(type,documentId){
            let url = type && type === 'all' ? this.requestUrl.documentExtract.getExtractResult+"?taskGroupId="+this.taskDetailObj.taskGroupId : this.requestUrl.documentExtract.getExtractResult+"?documentId="+documentId
            this.FetchGet(url).then(res => {
                if (res.code === "0") {
                    this.markContent = res.data;
                    this.extractList =  this.activeName === 'no-remark' ? this.markContent.noMarkedList : this.markContent.markedList;
                    this.allData = [];
                    this.markContent.noMarkedList.forEach(item => {
                        this.allData.push(Object.assign(item,{
                            type:'no-remark'
                        }))
                    })
                    this.markContent.markedList.forEach(item => {
                        this.allData.push(Object.assign(item,{
                            type:'remarked'
                        }))
                    })
                    if(this.activeDocumentId === 'all'){
                        this.activeDocumentObj = this.taskDetail.documentList[0];
                        this.fileName =  this.taskDetail.documentList[0].name;
                    } 
                    this.getDowumentHtml(this.activeDocumentId === 'all' ? this.taskDetail.documentList[0].documentId : this.activeDocumentObj.documentId,{},this.activeDocumentId === 'all' ? 'all' : 'one');
                    // this.getDowumentHtml(this.activeDocumentId === 'all' ? this.taskDetail.documentList[0].documentId : this.activeDocumentObj.documentId);
                } else {
                    this.markContent = {};
                    this.$message.error(res.message);
                }
            });
        },
        // 获取问文档的html内容
        getDowumentHtml(documentId = '',item={},type){
            console.log(documentId,'documentIddocumentIddocumentId');
            this.htmlDocumentId = documentId;
            this.FetchGet(this.requestUrl.documentExtract.getTextForHtmlTag+"?documentId="+documentId).then(res => {
                if (res.code === "0") {
                    this.allHtmlText = res.data;
                    this.htmlContent =  this.activeName === 'no-remark' ? res.data.noMarkedText : res.data.markedText;
                    if(this.taskDetailObj.taskGroupStatus < 4){
                        this.htmlContent = res.data.text;
                    }
                    if(item.id){
                        this.clickLeft(item);
                    }
                    if(type && type === 'all'){
                        if(res.data.text === ''){
                            if(this.options.length > 2){
                                // options documentId
                                let id = ''
                                this.options.forEach((v,i) => {
                                    if(v.documentId === documentId){
                                        id = this.options[i+1].documentId;
                                        this.fileName = this.options[i+1].name
                                    }
                                })
                                this.getDowumentHtml(id,item,type)
                            }
                        }
                    } else {
                        this.htmlLoading = false;
                        this.loading = false;
                    }
                } else {
                    this.htmlLoading = false;
                    this.loading = false;
                    this.$message.error(res.message);
                }
                this.loading = false;
            }).catch((err) => {
                this.htmlLoading = false;
                this.loading = false;
                this.$message.error('网络异常');
                console.log(err,'err');
            })
        },
        // 点击左侧定位 , 定位到右侧文档内容   黄色背景
        goLocation(item){
            console.log(item,'item');
            //  选择全部文档并且点击左侧定位时 , 如果当前问答不是右侧稳当内容时, 右侧文档自动切换到相对应的文档内容 , 并定位
            console.log(this.htmlDocumentId !== item.documentId);
            console.log(this.options);
            if(this.htmlDocumentId !== item.documentId){
                this.htmlLoading = true;
                let isPdf = false;
                this.options.forEach(v => {
                    if(v.documentId === item.documentId){
                        this.fileName = v.name;
                        if(v.type !== 'pdf' || v.type !== 'PDF'){
                            isPdf = true;
                        }
                    }
                })
                if(isPdf){
                    setTimeout(() => {
                        this.getDowumentHtml(item.documentId,item);
                    },1000) 
                } else {
                    this.getDowumentHtml(item.documentId,item);
                }
            } else {
                // let arr =  item.answer.split('\n');
                // console.log(arr,'arr');
                this.htmlLoading = false;
                // console.log('.scroll-box-item' + item.id + ' .extract-answer-box' + ' .answer-box');
                // let leftDom = document.querySelector('.scroll-box-item' + item.id  + ' .extract-answer-box' + ' .answer-box')
                // console.log(leftDom,'leftDom',leftDom.innerText);
                // // .askbot-location17234
                // let rightDom = document.querySelectorAll('.askbot-location' + item.id);
                // let rightDomList = Array.from(rightDom);
                // console.log(rightDomList,'rightDomList');
                
               
                // let text = arr[arr.length-1];
                // let indexList = [];
                // arr.forEach((_item,_index) => {
                //     rightDomList.forEach((v,i) => {
                //         console.log(v.innerText,'---');
                //         if(text === v.innerText){
                //             indexList.push(i);
                //         }
                //         if(indexList.length > 0){

                //         }
                //     }) 
                // })
                // console.log(indexList,'indexList');
                // for(let i = 0; i<arr.length +1;i++){
                //     for(let k = 0; k<rightDomList.length +1;k++){
                //         if(text === k.innerText){
                //             indexList.push(k);
                //         }
                //         if(indexList.length > 0){

                //         }
                //     }
                // }
                

                this.clickLeft(item);
            }
        },
        // 处理点击左侧 右侧变黄色定位,并滚动到相应位置
        clickLeft(item){
            console.log(item,'item');
            let res = this.htmlContent;
            this.allData.forEach((v,index) => {
                if(res.indexOf(v.id) !== -1){
                    let reg = new RegExp(' askbot-location-active','g')
                    res = res.replace(reg, "");
                }
            })
            if(res.indexOf(item.id) !== -1){
                // let oldText = `<span id="askbot${item.id}" class="askbot-document" onclick="clickSpanLocation(${item.id})">`;
                // this.htmlContent = res.replace(oldText, `<span id="askbot${item.id}" class="askbot-document askbot-location-active" onclick="clickSpanLocation(${item.id})">`);
                let oldText = 'askbot-location' + item.id;
                this.htmlContent = res.replace(new RegExp(oldText,'g'),`askbot-location${item.id} askbot-location-active`);
            }
            this.$nextTick(() => {
                
                let el =  document.querySelector('.right-html-content-detail .askbot-location-active');
                if(el){
                    // let leftDom = document.querySelector('.scroll-box-item' + item.id  + ' .extract-answer-box' + ' .answer-box');
                    // let leftDomText = leftDom.innerText.replace(/\)|\）|\(|\（/g,' ');
                    let leftDomText = item.answer.replace(/\)|\）|\(|\（/g,' ');
                    // leftDomText = leftDomText.replace(/\n|\r\n/g,'');
                    let rightDom = document.querySelectorAll('.askbot-location' + item.id);
                    let rightDomList = Array.from(rightDom);
                    console.log(rightDomList,'rightDomList');
                    
                    console.log(leftDomText,'leftDomText');
                    let leftTextList = leftDomText.split('\n');
                    let leftTextListLen = leftTextList.length;
                    leftTextList.forEach(v => {
                        v = v.replace(/\n|\r\n/g,'');
                    })
                    console.log(leftTextList,'leftTextList');

                    let domTextList = [];
                    let indArr = [];
                    let endArr = [];
                    let lastDom = null;
                    rightDomList.forEach((v,i) => {
                        let text = v.innerText.replace(/\)|\）|\(|\（/g,' ');
                        domTextList.push(text)
                    })
                    // 员工AI助手（AskBot）是基于自然语言处理，机器学习等技术AI对话机器人，由北京果然智汇科技有限公司自主研发，我们将复杂的对话理解与对话管理技术进行深度优化与产品设计，用户无需编程，即可快速定制一个具备多轮对话的对话机器人，并实现了自动创建工单、自动流转分派，通过机器学习技术实现AI全流程赋能；支持与各类系统打通，提供更多的智能化服务。"

                    console.log(domTextList,'domTextList');
                    if(domTextList.length > 0){
                        for (let domIndex = 0; domIndex < domTextList.length; domIndex++) {
                            domTextList[domIndex] = domTextList[domIndex].replace(/\)|\）/g,' ')
                            console.log(leftTextList[leftTextListLen - 1] ,'--------');
                            console.log( domTextList[domIndex],'%%%%%%%');
                            if (leftTextList[leftTextListLen - 1] === domTextList[domIndex]) {
                                console.log(domIndex,'domIndexdomIndexdomIndexdomIndexdomIndex');
                                indArr.push(domIndex - (leftTextListLen - 1));
                            }
                        }
                        console.log(indArr,'indArr');
                        indArr.forEach(item => {
                            if (domTextList[item] === leftTextList[0]) {
                                endArr.push(item);
                            }
                        })
                        console.log(endArr,'endArr');
                        let lastList = [];
                        // 如果endArr里面有两个角标 , 那么就再去加判断寻找最相近的一个
                        // 比如endArr返回两个 , 左侧数组长度为3 , 那么就用endArr里面的元素+3 , 获取每一个的纯文本 , 再去和左侧文本作对比 , 相等的就是最符合的那个
                        if(endArr.length > 1){
                            endArr.forEach(num => {
                                lastList.push({
                                    start:num,
                                    end:num + leftTextList.length-1,
                                    text:''
                                })
                            })
                            console.log(lastList,'lastList');
                            lastList.forEach((v,i) => {
                                domTextList.forEach((_v,_i) => {
                                        if(_i > v.start-1 && _i < v.end+1){
                                            console.log(_v,_i);
                                            v.text += _v
                                        }
                                })
                            })
                            console.log(lastList,'lastList222');
                            let allSame = []
                            lastList.forEach((v,i) => {
                                if(leftDomText === v.text){
                                    console.log(i,99999999);
                                    // lastDom = rightDomList[i];
                                    allSame.push(v)
                                }
                            })
                            console.log(allSame,'allSame');
                            lastDom = rightDomList[allSame[0].start];
                        } else if(endArr.length === 1){
                            lastDom = rightDomList[endArr[0]];
                        } else {
                            lastDom = rightDomList[0];
                        }
                        console.log('*****',lastDom);
                    

                        lastDom.scrollIntoView({
                            behavior: "smooth", // 平滑过渡
                            block: "center", // 上边框与视窗顶部平齐。默认值
                        });
                    } else {
                        this.$message.closeAll();
                        this.$message.warning('暂未匹配到合适位置')
                    }
                } else {
                    this.$message.closeAll();
                    this.$message.warning('暂未匹配到合适位置')
                }
                this.$forceUpdate();
            })
        },
        // 点击右侧文档 定位到左侧答案
        clickSpanLocation(e,id){
            console.log(e,'eeeeee');
            event.stopPropagation();
            if(this.activeEl){
                this.activeEl.classList.remove('active-qa');
                console.log(this.activeEl.classList);
            }
            let el =  document.querySelector('.scroll-box-item'+id);
            this.activeEl = el;
            el.classList.add('active-qa');
            this.$nextTick(() => {
                document.querySelector(".active-qa").scrollIntoView({
                    behavior: "smooth", // 平滑过渡
                    block: "center", // 上边框与视窗顶部平齐。默认值
                });
            })
        },
        // 未标注/已标注切换
        switchTab(name){
            this.activeName = name;
            if( name === 'no-remark'){
                this.extractList = this.markContent.noMarkedList;
                this.htmlContent = this.allHtmlText.noMarkedText;
            } else {
                this.extractList = this.markContent.markedList;
                this.htmlContent = this.allHtmlText.markedText;
            }
        },
        handleCloseTaskStructure() {
           this.$emit('closeDetail')
        },
        // 编辑任务
        editTask(){
            // this.$emit('editTask',this.taskDetailObj)
            this.addTaskVisible = true;
        },
        // 删除任务
        delTask(){
            this.$emit("popupDelTask",this.taskDetailObj.taskGroupId);
        },
        // 暂停任务
        stopTask(){
            this.$confirm('暂停任务将重置进度，是否暂停任务?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.FetchGet(this.requestUrl.documentExtract.stopTask+'/'+this.taskDetailObj.taskGroupId)
                    .then(res=>{
                        if (res.code === '0'){
                            this.$message({
                                message:'暂停任务成功',
                                type:'success',
                                duration:1000
                            })
                            clearTimeout(this.timer)
                            this.getTaskDetail(this.taskDetailObj.taskGroupId);
                            this.$emit('stopTask',this.taskDetailObj);
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消暂停任务'
                });          
            });
        },
        // 立即抽取
        nowExtract(){
            this.FetchGet(this.requestUrl.documentExtract.extractingNow+"?taskGroupId="+this.taskDetailObj.taskGroupId).then(res => {
                if (res.code === "0") {
                    this.$emit('extractingNow');
                    this.getTaskDetail(this.taskDetailObj.taskGroupId);
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        },
        // 切换文件
        changeFile(){
            if(this.activeDocumentId !== 'all'){
                this.getExtractResultType = 'one';
                this.getExtractResult('one',this.activeDocumentId); 
                
            } else {
                this.getExtractResultType = 'all';
                this.getExtractResult('all',this.activeDocumentId); 
            }
            this.options.forEach(item => {
                if(this.activeDocumentId === item.documentId){
                    this.fileName = item.name;
                    this.activeDocumentObj = item;
                }
            })
        },
        // 打开添加抽取结果弹框
        addExtractResult(item,index){
            this.previewImageUrl = '';
            this.addExtractResultVisible = true;
            this.activeMarkItem = item;
            this.activeMarkItem.oldText = item.answer;
            // console.log(this.activeMarkItem,'activeMarkItemactiveMarkItem');
            // // allHtmlText
            // let i = this.allHtmlText.text.indexOf(item.text);
            // // console.log(i,item.text.length);
            // let imgStr = this.allHtmlText.text.slice(i,i+300+item.text.length);
            // console.log(imgStr,'-------imgStr');
            // let srcIndex = imgStr.indexOf('src');
            // let imgIndex = imgStr.indexOf('<img');
            // if(imgIndex > -1){
            //     // console.log(srcIndex,'srcIndex----',imgIndex);
            //     let classStr = imgStr.slice(imgIndex,srcIndex);
            //     // console.log(classStr);
            //     let first = classStr.indexOf('"');
            //     let end = classStr.lastIndexOf('"');
            //     let className = classStr.slice(first+1,end);
            //     console.log('.hidden-text' + ' .' + className);
            //     let allDom = document.querySelector('.hidden-text' + ' .' + className);
            //     // console.log(allDom.getAttribute("src"),'allDom');
            //     this.previewImageUrl = allDom.getAttribute("src");
            // }
        },
        // 添加抽取结果成功
        addExtractingResultSuccess(){
            this.addExtractResultVisible = false;
            // 只要有一个标注 , 状态就要切换
            if(this.taskDetailObj.taskGroupStatus === 4){
                this.getTaskDetail(this.taskDetailObj.taskGroupId);
                this.$emit('addTaskSuccess',{taskGroupId:this.taskDetailObj.taskGroupId},'edit')
            }
            this.getExtractResult(this.getExtractResultType,this.activeDocumentId);
        },
        // 定位到知识库页面
        openIntentWeb(item,index){
            console.log(item);
            let routeData = this.$router.resolve({
                path: '/main/botIntent', 
                query: {
                    id: item.botId, 
                    name:item.botName,
                    refApiKey:item.refApiKey,
                    from:'cursor',
                    tabIndex:"skill"
                }
            });
            console.log(routeData.href,'---');
            sessionStorage.setItem("_activeSkill", item.skillId);
            sessionStorage.setItem("_activeClass", item.typeId);
            sessionStorage.setItem("openIntentWeb", 1);
            sessionStorage.setItem("openIntentId", item.intentId);
            window.open(
                window.location.href.split("/#/")[0] +
                    "/#" +
                    routeData.resolved.fullPath,
                "_blank"
            );
            // switchSkillClass    // 获取意图列表  this.activeIntent
        }
    },
};
</script>

<style scoped lang="less">
@import "../../../assets/less/smartDocumentExtract/taskDetail.less";
</style>