<!--添加抽取结果-->
<template>
    <div class="add-extract-result">
        <popup @closeEvent="closeEvent" v-if="addExtractResultVisible">
            <div slot="popup-name">添加抽取结果</div>
            <div slot="popup-tip">将抽取结果应用到机器人中</div>
            <div slot="popup-con"> 
                <div class="popup-box">
                    <div :class="['left-detail',useType === 2 ? 'add-had' : '']">
                        <ul class="addIntentInfo">
                                <div :class="['checked-intent recommend-intent',isShow ? 'hide-box' : '']" >
                                    <div class="title" v-if="recommendIntent.length > 0">已有意图推荐</div>
                                    <div :class="['checked-intent-box recommend-intent-content',recommendIntent.length === 0 && isShowNoData ? 'no-data' : '',animateBoo ? 'animateBoo':'',recommendIntent.length > 0 ? 'have-data' : '']" v-loading="loading"  element-loading-text="系统正在检测知识库中可能相似的意图"  element-loading-background="#FBFCFD">
                                        <template v-if="recommendIntent && recommendIntent.length > 0">
                                            <div class="checked-intent-item" v-for="(item,index) in recommendIntent" :key="item.id">
                                                <div class="intent-names">
                                                    <div class="bot-name one-column-ellipsis">{{item.botName}}</div>
                                                    <div class="intent-name">
                                                        <span class="one-column-ellipsis">{{item.intentName}}</span>
                                                        <i class="iconfont guoran-tongyichicun-biaozhu" @click="clickIntentRecommend(item,index)"></i>
                                                    </div>

                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="recommendIntent.length === 0 && isShowNoData">
                                            <div class="no-data">暂未检测到可能相似的意图</div>
                                        </template>
                                    </div>
                                </div>
                           
                            <li class="item-config">
                                <div class="block-add-intent share-radio">
                                    <span class="title">使用方式</span>
                                    <el-radio-group v-model="useType" @change="changeUseType">
                                        <el-radio :label="1">新建意图</el-radio>
                                        <el-radio :label="2">添加至已有意图</el-radio>
                                    </el-radio-group>
                                </div>
                                <!-- <div class="tips" v-if="useType == 2">会默认将问法添加至意图中，您也可以使用答案到对应的意图中，但会覆盖掉原有的对话流程</div> -->
                            </li>
                            <template v-if="useType === 1">
                                <li class="item-config">
                                    <div class="block-add-intent">
                                        <span class="title">
                                            <span class="left">意图名称 <span class="red-text">*</span></span>
                                            <span class="right-tips">已将抽取的答案自动填充</span>
                                        </span>
                                        <el-input
                                            v-model="intentName"
                                            maxlength="30"
                                            placeholder="请输入意图名称"
                                        ></el-input>
                                    </div>
                                </li>
                                <li class="item-config">
                                    <div class="block-add-intent">
                                        <span class="title">
                                            <span class="left">示例问法 <span class="red-text">*</span></span>
                                            <span class="right-tips">已将抽取的答案自动填充</span>
                                        </span>
                                        <el-input
                                            v-model="exampleQuestion"
                                            maxlength="30"
                                            placeholder="请输入"
                                        ></el-input>
                                    </div>
                                </li>
                                <!-- <li class="item-config">
                                    <div class="block-add-intent share-radio">
                                        <span class="title">共享意图</span>
                                        <el-radio-group v-model="isShare">
                                            <el-radio :label="1">是</el-radio>
                                            <el-radio :label="0">否</el-radio>
                                        </el-radio-group>
                                    </div>
                                </li> -->
                            </template>
                             <li :class="['item-config',useType === 2 ? 'add-had' : '']">
                                <div class="qa-name one-column-ellipsis" v-if="useType === 2">问题：{{activeMarkItem.question}}</div>
                                <div class="divider"></div>
                                <div class="classification">
                                    <span class="title classification-title">{{useType === 1 ? '所属机器人、知识库及分类' : '选择意图'}}  <span class="red-text">*</span></span>
                                    <el-cascader
                                        :key="new Date().getTime()"
                                        :disabled="isDefault"
                                        v-model="classification"
                                        style="width: 100%"
                                        :props="{ emitPath: true,value:'id',label:'name' }"
                                        :options="intentOptions"
                                    ></el-cascader>
                                </div>
                            </li>
                            <li class="item-config">
                                <div class="classification">
                                    <div class="title">
                                        <span class="left">意图答案 
                                            <div class="top">
                                                <div class="evaluate-table-switch">
                                                    <el-switch
                                                        @click.native.prevent="changeEnable2('switch')"
                                                        :width="42"
                                                        v-model="intentAnswer"
                                                        active-color="#366AFF"
                                                        inactive-color="#E2E2E2">
                                                        >
                                                        </el-switch>
                                                        <span
                                                        class="switch-open-icon"
                                                        @click="changeEnable2('open')"
                                                        v-if="intentAnswer"
                                                        ><i class="iconfont guoran-a-16-17"></i
                                                        ></span>
                                                        <span
                                                        class="switch-close-icon"
                                                            @click="changeEnable2('close')"
                                                        v-if="!intentAnswer"
                                                        ><i class="arsenal_icon arsenalcuo1"></i
                                                        ></span>
                                                </div> 
                                            </div>
                                        </span>
                                        <div class="right-tips">{{useType === 1 ? '如需配置复杂的对话流程。请转至对话流程处配置' : '使用抽取到的答案到已有意图中会覆盖掉原有的对话流程'}}</div>
                                    </div>
                                    <div class="intent-answer-box">
                                        <div class="bgc-box">
                                            <div class="middle">
                                                <el-radio-group v-model="intentAnswerType">
                                                    <el-radio :label="0" >富文本</el-radio>
                                                    <el-radio :label="1" class="edit">文本</el-radio>
                                                </el-radio-group> 
                                                <span class="title">
                                                    <span class="right-tips">已将抽取的答案自动填充</span>
                                                </span>
                                            </div>
                                            <div class="intent-answer-value">
                                                <el-input v-model="intentAnswerValueInput" v-if="intentAnswerType === 1" placeholder="请输入内容"></el-input>
                                                <ckeditor
                                                    v-if="intentAnswerType === 0"
                                                    style="height: 300px;overflow-y: scroll"
                                                    :editor="ckeditor.editor"
                                                    v-model="intentAnswerValue"
                                                    :config="editorConfig"
                                                    @focus="ckeditorFocus"
                                                    ref="ckeditor"
                                                    @ready="(editor)=>currentEditor=editor"
                                                ></ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                          
                        </ul>
                        <!-- <ul
                            class="superIntentConfig-add-inetent"
                            v-show="this.handleTypeC == 'add'&&useType === 1"> -->
                            <!-- <li class="item-config">
                                <div class="block-add-intent open-super-intent">
                                    <span class="title">启用超级意图</span>
                                    <div class="right">
                                        <div class="evaluate-table-switch">
                                            <el-switch
                                                @click.native.prevent="changeEnable('switch')"
                                                :width="42"
                                                v-model="currentSuperIntentList.enable"
                                                active-color="#366AFF"
                                                inactive-color="#E2E2E2">
                                            >
                                            </el-switch>
                                            <span
                                            class="switch-open-icon"
                                            @click="changeEnable('open')"
                                            v-if="currentSuperIntentList.enable"
                                            ><i class="iconfont guoran-a-16-17"></i
                                            ></span>
                                            <span
                                            class="switch-close-icon"
                                                @click="changeEnable('close')"
                                            v-if="!currentSuperIntentList.enable"
                                            ><i class="arsenal_icon arsenalcuo1"></i
                                            ></span>
                                        </div>
                                        <div class="desc">
                                            启用超级意图功能后，在您添加问法或规则较少的情况下，能提高识别能力
                                        </div>
                                    </div>
                                </div>
                            </li> -->
                            <!-- <li class="item-config">
                                <div class="classification">
                                    <div class="title">
                                        <span class="left">意图答案 
                                            <div class="top">
                                                <div class="evaluate-table-switch">
                                                    <el-switch
                                                        @click.native.prevent="changeEnable2('switch')"
                                                        :width="42"
                                                        v-model="intentAnswer"
                                                        active-color="#366AFF"
                                                        inactive-color="#E2E2E2">
                                                        >
                                                        </el-switch>
                                                        <span
                                                        class="switch-open-icon"
                                                        @click="changeEnable2('open')"
                                                        v-if="intentAnswer"
                                                        ><i class="iconfont guoran-a-16-17"></i
                                                        ></span>
                                                        <span
                                                        class="switch-close-icon"
                                                            @click="changeEnable2('close')"
                                                        v-if="!intentAnswer"
                                                        ><i class="arsenal_icon arsenalcuo1"></i
                                                        ></span>
                                                </div> 
                                            </div>
                                        </span>
                                        <div class="right-tips">如需配置复杂的对话流程。请转至对话流程处配置</div>
                                    </div>
                                    <div class="intent-answer-box">
                                        <div class="bgc-box">
                                            <div class="middle">
                                                <el-radio-group v-model="intentAnswerType">
                                                    <el-radio :label="0" >富文本</el-radio>
                                                    <el-radio :label="1" class="edit">文本</el-radio>
                                                </el-radio-group> 
                                                <span class="title">
                                                    <span class="right-tips">已将抽取的答案自动填充</span>
                                                </span>
                                            </div>
                                            <div class="intent-answer-value">
                                                <el-input v-model="intentAnswerValue" v-if="intentAnswerType === 1" placeholder="请输入内容"></el-input>
                                                <ckeditor
                                                    v-if="intentAnswerType === 0"
                                                    style="height: 300px;overflow-y: scroll"
                                                    :editor="ckeditor.editor"
                                                    v-model="intentAnswerValue"
                                                    :config="editorConfig"
                                                    @focus="ckeditorFocus"
                                                    ref="ckeditor"
                                                    @ready="(editor)=>currentEditor=editor"
                                                ></ckeditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li> -->
                            <!-- <li class="item-config">
                                <div
                                    class="block-add-intent"
                                    v-show="currentSuperIntentList.enable"
                                >
                                    <span class="title">
                                        <i
                                            class="iconfont guoran-tongyichicun-shili"
                                        ></i>
                                        选择超级意图类别</span
                                    >
                                    <el-select
                                        :key="intentClassKey"
                                        v-model="selectData.name"
                                        @visible-change="
                                            selectVisibleChange($event)
                                        "
                                        @focus="superIntentFocus()"
                                        @change="selectTrigger()"
                                        filterable
                                        placeholder="请选择"
                                    >
                                        <el-option
                                            :class="[clearStatus ? 'clearStatus' : 'no']"
                                            v-for="item in superIntentList"
                                            :key="item.id"
                                            :value="item.name"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>
                            </li>
                            <li
                                class="exmplebox item-config"
                                v-show="
                                    currentSuperIntentList.enable &&
                                    selectData.examples != ''
                                "
                            >
                                <span class="title">超级意图问法示例</span>
                                <div class="exmple">
                                    <div
                                        v-for="item in selectData.examples"
                                        :key="item.id"
                                        class="text"
                                    >
                                        <div></div>
                                        {{ item }}
                                    </div>
                                </div>
                            </li>
                            <li class="item-config" v-show="useType === 1&&currentSuperIntentList.enable&&selectData.examples != ''">
                                <div class="blockdes">
                                    <span class="title">
                                        <i
                                            class="iconfont guoran-tongyichicun-shili"
                                        ></i>
                                        请按照示例造句，将相应的词填入输入框内
                                    </span>
                                    <span class="des"
                                        >（如词语存在近义词，也请填写，多个词之间用逗号分隔）</span
                                    >
                                </div>
                                <div class="config">
                                    <div
                                        class="pattern"
                                        v-for="(item, index) in selectData
                                            .templateConfig.pattern"
                                        :key="index"
                                    >
                                    <span class="keyword-recommend">
                                        <el-input
                                            @click.stop.native="changeInputSuper($event,item,index,'focus')"
                                            @input="changeInputSuper($event,item,index)"
                                            v-model.trim="item.value"
                                            size="small"
                                            placeholder="输入关键词"
                                            v-show="
                                                item.isFillIn == true &&
                                                item.type != 1
                                            "
                                        ></el-input>
                                        <botKeywordRecommend 
                                            :key="'superIntentConfigs' + index"
                                            :showBotKeywordRecommend.sync="item.showBotKeywordRecommend"
                                            :dataObj="item"
                                            :dataIndex="index"
                                            :keyword="item.value"
                                            :left="0"
                                            :positionTop="36"
                                            @showBotKeywordRecommend="showBotKeywordRecommendFn"
                                            @closeBotKeywordRecommend="closeBotKeywordRecommend(item)"
                                            @addToInput="addToInputSuper($event,item,index)">
                                        </botKeywordRecommend>
                                    </span>
                                        <span
                                            class="word"
                                            v-show="
                                                item.type == 1 &&
                                                item.isFillIn == false
                                            "
                                            >{{ item.words[0] }}</span
                                        >
                                    </div>
                                </div>
                                <div class="pattern config-example">
                                    <div class="title"><span>配置示例</span></div>
                                    <div class="bottom-example">
                                        <div
                                            :class="[
                                                'pattern',
                                                item.isFillIn ? 'border' : '',
                                            ]"
                                            v-for="(item, index) in selectData
                                                .templateConfig.example"
                                            :key="index"
                                        >
                                            <span
                                                :class="[
                                                    item.type != '1'
                                                        ? 'word input'
                                                        : 'word',
                                                ]"
                                                v-for="(
                                                    todo, cindex
                                                ) in selectData.templateConfig.example[
                                                    index
                                                ].words.join(',')"
                                                :key="cindex"
                                                >{{ todo }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </li> -->
                        <!-- </ul> -->
                    </div>
                    <div :class="['keyword-ecommend-content-right',useType === 2 ? 'add-had' : '']">
                        <!-- <div class="keyword-ecommend-content-right-title">
                            <i class="iconfont guoran-tongyichicun-jiqirentouxiang"></i>
                            <span>AskBot智能助手</span>
                        </div>
                        <div class="checked-intent">
                            <div class="checked-intent-title">问题</div>
                            <div class="checked-intent-box">
                                <div class="checked-intent-item">
                                    <div class="intent-names one-column-ellipsis">{{activeMarkItem.question}}</div>
                                </div>
                            </div>
                            <div class="checked-intent-title answer">答案</div>
                            <div class="checked-intent-box">
                                <div class="checked-intent-item answer">
                                    <div class="intent-names">{{activeMarkItem.answer}}</div>
                                </div>
                            </div>
                        </div> -->
                        
                        <div class="checked-intent verbatim-texts">
                            <div class="checked-intent-title">原文片段</div>
                            <div class="checked-intent-box verbatim-texts-box">
                                <template v-if="topImg && topImg.length > 0">
                                    <div v-for="(topItem,topIndex) in topImg" :key="topIndex"  class="top-img-box-list">
                                        <el-image
                                            :class="['html-content-image']+topItem.id"
                                            v-if="topItem && topItem !== ''"
                                            style="width: 260px; height: 102px;margin-bottom:8px"
                                            :src="topItem.url" 
                                            fit="fill">
                                        </el-image>
                                        <div class="operation-btn" v-if="topItem && topItem !== ''">
                                            <div class="preview-img" @click="previewImage(topItem)"> <i class="guoran-tongyichicun-dianjichakantuli iconfont"></i> 查看大图</div>
                                            <div :class="['copy-img','copy-img'+topItem.id]" data-clipboard-action="copy" @click="copyImage(topItem)"> <i class="guoran-tongyichicun-fuzhi iconfont"></i> 复制</div>
                                        </div>
                                    </div> 
                                </template>
                                <div v-html="this.activeMarkItem.text" class="verbatim-texts-text"></div>
                                <template v-if="bottomImg && bottomImg.length > 0">
                                    <div v-for="(bottomItem,bottomIndex) in bottomImg" :key="bottomIndex" class="bottom-img-box-list">
                                        <el-image
                                            :class="['html-content-image']+bottomItem.id"
                                            v-if="bottomItem && bottomItem !== ''"
                                            style="width: 260px; height: 102px;margin-bottom:8px"
                                            :src="bottomItem.url" 
                                            fit="fill">
                                        </el-image>
                                        <div class="operation-btn" v-if="bottomItem && bottomItem !== ''">
                                            <div class="preview-img" @click="previewImage(bottomItem)"> <i class="guoran-tongyichicun-dianjichakantuli iconfont"></i> 查看大图</div>
                                            <div :class="['copy-img','copy-img'+bottomItem.id]" data-clipboard-action="copy" @click="copyImage(bottomItem)"> <i class="guoran-tongyichicun-fuzhi iconfont"></i> 复制</div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="dialog-footer">
                <el-button @click="closeEvent" plain round class="cancel-btn">取消</el-button>
                <el-button class="confirm-btn" type="primary" @click="submitForm('ruleForm')" round style="padding:0 !important;" :loading="saveBtnLoading">保存</el-button>
            </div>
        </popup>
        <div
            v-if="showPreview"
            style="
                width: 100vw;
                height: 100vh;
                z-index: 10000;
                position: fixed;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.8);
            "
        >
            <div
                @click="showPreview = false"
                style="
                    color: white;
                    font-size: 30px;
                    padding: 30px 30px 15vh;
                    text-align: right;
                    cursor: pointer;
                ">
                X
            </div>
            <img
                style="
                    max-height: 70vh;
                    max-width: 70vw;
                    margin: 0 auto;
                    display: flex;
                "
                :src="previewImageUrl"
                alt
                srcset/>
        </div>
        <div :class="['hanler-img'+item.id]" v-html="item.picture" style="display:none" v-for="(item) in textDomlist" :key="item.id"></div>
        <div style="display:none" class="intentAnswerValue-box" v-html="intentAnswerValue"></div>
    </div>
</template>

<script>
import Popup from '../../../components/popup.vue';
import CKEDITOR from "ckeditor";
import {MyCustomUploadAdapterPlugin} from "../../fissionMarketing/components/ckeditor";
import Clipboard from "clipboard";
export default {
    data(){
        return{
            saveBtnLoading:false,
            useType:1,
            intentName:'',
            isShare: 0,
            isDefault: false,
            classification: [],
            classificationOption: [],
            handleTypeC: "add", // 意图操作弹窗类型
            currentSuperIntentList: {
                corpusTemplate: { examples: [""], example: "" },
                name: "diyige",
            },
            intentClassKey:1,
            selectData: {
                id: "",
                name: "",
                examples: [],
                templateConfig: {
                    pattern: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                    example: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                },
            },
            startSelectData: {
                id: "",
                name: "",
                examples: [],
                templateConfig: {
                    pattern: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                    example: [
                        {
                            varName: "",
                            type: "",
                            words: [],
                            isFillIn: null,
                            value: "",
                             showBotKeywordRecommend:false
                        },
                    ],
                },
            },
            clearStatus:true,
            recommendIntent:[],
            intentAnswer:true,
            intentAnswerType:0,
            intentAnswerValue:1,
            intent:"",
            intentOptions:[],
            superIntentInputWords:[],
            ckeditor: {
                editor: CKEDITOR.ClassicEditor
            },
            editorConfig: {
                extraPlugins: [MyCustomUploadAdapterPlugin],
                toolbar: [
                    'imageUpload',
                ],
            },
            currentEditor:null,
            loading:true,
            showPreview:false, // 查看大图
            clipboardExample:null,
            topImg:[],
            bottomImg:[],
            textDom:'',
            textDomlist:[],
            previewImageUrl:'',
            isShowNoData:false,
            animateBoo:false,
            isShow:false,
            intentAnswerValueInput:''
        }
    },
    components:{Popup},
    props:{
        addExtractResultVisible:{
            type:Boolean,
            default:false
        },
        activeMarkItem:{
            type:Object,
            default(){
                return{}
            }
        },
        taskDetailObj:{
            type:Object,
            default(){
                return{}
            }
        },
    },
    watch:{
        addExtractResultVisible(n){
            if(n){
                this.loading = true;
                this.isShowNoData = false;
                this.animateBoo = false;
                this.isShow = false;
                this.recommendIntent = [];
                this.intentAnswerValue = this.activeMarkItem.answer;
                this.intentAnswerValueInput = this.activeMarkItem.answer;
                this.selectPdfDocumentPicByDocumentId();
                this.handlerSurper();
                this.getSuperIntentList();
                this.getIntentTree(false);
                this.getIntentRecommend();
                this.intentName = this.activeMarkItem.question;
                this.exampleQuestion = this.activeMarkItem.question;
                this.intentAnswerValue = this.activeMarkItem.answer;
                let text = this.activeMarkItem.text;
                if(this.activeMarkItem.text.indexOf(this.activeMarkItem.answer) > -1){
                    this.activeMarkItem.text = this.activeMarkItem.text.replace(this.activeMarkItem.answer,'<span class="active-text">' + this.activeMarkItem.answer + '</span>')
                }
            }
        },
        intentAnswerValue(n,o){
            this.intentAnswerValue = n.replace(/style\s*?=\s*?([‘"])[\s\S]*?\1/, "");
            this.$nextTick(() => {
                this.intentAnswerValueInput = document.querySelector('.intentAnswerValue-box').innerText;
                console.log(document.querySelector('.intentAnswerValue-box').innerText);
                console.log(this.intentAnswerValue,'nnnn');
            })
        },
        intentAnswerValueInput(n){
            
        }
    },
    methods:{
        // 获取当前文档的图片及其上下文信息
        selectPdfDocumentPicByDocumentId(){
            this.FetchGet(this.requestUrl.documentExtract.selectPdfDocumentPicByDocumentId+'?documentId='+ this.activeMarkItem.documentId).then(res => {
                console.log(res.data,'-----');
                if (res.code === "0") {
                    this.textDomlist = res.data;
                    this.$nextTick(() => {
                        this.bottomImg = [];
                        this.topImg = [];
                        let list = this.activeMarkItem.oldText.split('\n')
                        console.log(list,'listlistlistlist');
                        console.log(this.activeMarkItem.oldText,'this.activeMarkItem.oldText');
                        let topIds = [];
                        let bottomIds = [];
                        list.forEach((textItem,textIndex) => {
                            let flag = false;
                            res.data.forEach((item,index) => {
                                if(!item.aboveText){
                                    item.aboveText = ''
                                }
                                if(!item.belowText){
                                    item.belowText = ''
                                }
                                // 上文
                                if(item.aboveText.indexOf(textItem) !== -1){
                                    flag = true;
                                    console.log('.hanler-img'+item.id);
                                    let dom = document.querySelector('.hanler-img'+item.id + ' img')
                                    console.log(dom,'dom',item.id);
                                    // this.textDom = item.picture;
                                    bottomIds.push(item.id);
                                    if(topIds.indexOf(item.id) === -1){
                                         this.bottomImg.push({
                                            url:dom.getAttribute('src'),
                                            id:item.id
                                        })
                                    }
                                } 
                                if(!flag){
                                    // 下文
                                    if(item.belowText.indexOf(textItem) !== -1){
                                        console.log('.hanler-img'+item.id);
                                        let dom2 = document.querySelector('.hanler-img'+item.id + ' img')
                                        console.log(dom2,'dom2',item.id);
                                        topIds.push(item.id);
                                        if(bottomIds.indexOf(item.id) === -1){
                                            this.topImg.push({
                                                url:dom2.getAttribute('src'),
                                                id:item.id
                                            })
                                        }
                                        // this.topImg = [...this.topImg,...[{
                                        //     url:"https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwx4.sinaimg.cn%2Fmw690%2F0083y32Agy1h6e6btftwpj31gh35s0wi.jpg&refer=http%3A%2F%2Fwx4.sinaimg.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1666433380&t=826615bcecfa2cb7a52c3769361d86d2",
                                        //     id:1
                                        // }]]
                                    }
                                }
                            })
                        })
                        this.topImg = this.handlerArray(this.topImg);
                        this.bottomImg = this.handlerArray(this.bottomImg)
                        console.log(this.bottomImg,'bottomImg');
                        console.log(this.topImg,'topImg');
                    })
                } else {
                    this.$message.error(res.message);
                }
            });
        },
         // 数组对象去重
        handlerArray(arr) {
            let map = new Map();
            for (let item of arr) {
                map.set(item.id, item);
            }
            arr = [...map.values()];
            return arr
        },
        handlerSurper(){
            this.intentAnswerValue = this.activeMarkItem.answer;
            this.intentAnswerValueInput = this.activeMarkItem.answer;
            this.intentAnswerType = 0;
            this.previewImageUrl = '';
            this.isShare = 0;
            this.intentAnswer = true;
            this.classification = [];
            this.useType = 1;
            this.intentName = "";
            this.exampleQuestion = "";
            this.isDefault = false;
            this.handleTypeC = "add";
            this.currentSuperIntentList.enable = false;
            this.selectData.name = '';
            this.clearStatus = true;
            this.intentClassKey = 1;
            this.selectData.examples = [];
            let templateConfig = {
                pattern: [
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                            showBotKeywordRecommend:false
                    },
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                            showBotKeywordRecommend:false
                    },
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                            showBotKeywordRecommend:false
                    },
                ],
                example: [
                    {
                        varName: "",
                        type: "",
                        words: [],
                        isFillIn: null,
                        value: "",
                            showBotKeywordRecommend:false
                    },
                ],
            }
            this.selectData.templateConfig = templateConfig
        },
        selectVisibleChange(callback) {
            if (callback) {
                this.clearStatus = true;
                this.selectData = this.startSelectData;
            } else {
                this.clearStatus = false;
                this.selectTrigger();
            }
        },
         // 超级意图列表获取焦点后清空所选内容
        superIntentFocus() {
            this.selectData = this.startSelectData;
            this.getSuperIntentList();
        },
         // 选中超级意图执行方法
        selectTrigger(id) {
            this.clearStatus = false;
            console.log(this.selectData,111111);
            for (var i = 0; i < this.superIntentList.length; i++) {
                if (this.superIntentList[i].name == this.selectData.name) {
                    this.selectData = this.superIntentList[i];
                }
            }
            if(this.currentSuperIntentList.enable){
                this.$nextTick(() => {
                    let ele =  document.querySelector('.left-detail');
                    ele.scrollTop = ele.scrollTop + 100;
                })
            }
        },
         // 获取超级意图列表
        getSuperIntentList() {
            this.FetchGet(this.requestUrl.superIntent.getSuperIntentList).then(
                (res) => {
                    if (res.code === "0") {
                        this.superIntentList = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }
            );
        },
        showBotKeywordRecommendFn(){
            this.$nextTick(() => {
                let ele =  document.querySelector('.add-extract-result .popup-container');
                ele.scrollTop = ele.scrollTop + 100;
            })  
        },
         // 超级意图 显隐推荐词下拉框
        changeInputSuper(datas,item,index){
            this.isIntentFocus = true;
            this.selectData.templateConfig.pattern.forEach((v,i) => {
                v.showBotKeywordRecommend = false;
            })
            if(item.value !== ''){
                item.showBotKeywordRecommend = true;
            } 
            this.$forceUpdate();
        },
        closeBotKeywordRecommend(item){
            item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
        //超级意图 关键词的近义词 , 单个添加
        addToInputSuper(datas,item,index){
            item.value = datas;
            // item.showBotKeywordRecommend = false;
            this.$forceUpdate()
        },
        changeEnable(datas){
            if(datas === 'open'){
                this.currentSuperIntentList.enable = false;
            } else if (datas === 'close'){
                this.currentSuperIntentList.enable = true;
            }
            this.intentClassKey++
            this.getSuperIntentList();
        },
        changeEnable2(datas){
            if(datas === 'open'){
                this.intentAnswer = false;
            } else if (datas === 'close'){
                this.intentAnswer = true;
            }
        },
        // handleChange2(value) {
        //     this.getTypeListChildren(value[0]);
        // },
        getTypeListChildren(id,type) {
            this.FetchGet(this.requestUrl.intent.getIntentTypeList, {
                skillId: id,
            }).then((res) => {
                if (res.code === "0") {
                    this.classificationOption.forEach((item,index) => {
                         if (item.value === id) {
                            let test = res.data.map((res) => {
                                return { label: res.name, value: res.id };
                            });
                            item.children = JSON.parse(JSON.stringify(test));
                        }
                    })
                }
            });
        },
        getIntentTree(type) {
            this.FetchGet(this.requestUrl.documentExtract.getIntentTree+'?needIntent='+type).then((res) => {
                if (res.code === "0") {
                    res.data.forEach((item,index) => {
                        if(item.children && item.children.length > 0){
                            item.children.forEach((childrenItem,childrenIndex) => {
                                if(childrenItem.name === '特殊规则'){
                                    item.children.splice(childrenIndex,1)
                                }
                            })
                        }
                    })
                    this.intentOptions = res.data || [];
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        changeUseType(){
            // useType 1 新建   2 已有
            // intentAnswerType 1 文本  0 富文本
            if(this.useType == 1){
                this.intentAnswer = true;
                this.intentAnswerType = 0;
                this.classification = [];
            } else {
                this.intentAnswer = false;
                this.intentAnswerType = 0;
                this.classification = [];
            }
            this.getIntentTree(this.useType == 1 ? false : true);
        },
        ckeditorFocus(zh,editor){
            this.currentEditor=editor;
        },
        // 智能助手推荐标注意图
        getIntentRecommend(question){
            this.FetchGet(this.requestUrl.documentExtract.intentRecommend+'?question='+this.activeMarkItem.question).then(
                (res) => {
                    if (res.code === "0") {
                        this.recommendIntent = res.data || [];
                        this.loading = false;
                        this.animateBoo = false;
                        if(this.recommendIntent.length === 0){
                            this.isShowNoData = true;
                            setTimeout(() => {
                                this.isShowNoData = false;
                                this.animateBoo = true;
                            },2000)
                            setTimeout(() => {
                                this.isShow = true;
                            },2800)
                        }
                    } else {
                        this.loading = false;
                        this.animateBoo = true;
                        this.$message.error(res.message);
                    }
                }
            );
        },
        clickIntentRecommend(item,index){
            this.useType = 2;
            this.intentAnswer = false;
            this.intentAnswerType = 0;
            this.getIntentTree(this.useType == 1 ? false : true);
            this.classification = ['B'+ item.botId,'S' + item.skillId,'T' + item.typeId,item.intentId + ''];
        },
        closeEvent(){
            this.$emit('update:addExtractResultVisible',false)
        },
       
        submitForm(formName){
            if(this.classification.length === 0){
                this.$message.error(this.useType === 1 ? '请选择超级所属机器人、知识库及分类' : '请选择超级所属机器人、知识库、分类及意图');
                return;
            }
            if(this.intentName.length === 0 && this.useType === 1){
                this.$message.error("请输入意图名称！");
                return;
            }
            this.superIntentInputWords = [];
            let pattern = this.selectData.templateConfig.pattern;
            // if (this.currentSuperIntentList.enable == true) {
            //     for (var i = 0; i < pattern.length; i++) {
            //         if (pattern[i].words.length != 0) {
            //             let content = pattern[i].words[0].split(" ");
                      
            //             this.superIntentInputWords.push({
            //                 varName: pattern[i].varName,
            //                 type: pattern[i].type,
            //                 words: content,
            //                 isFillIn: pattern[i].isFillIn,
            //             });
            //         } else if (pattern[i].value) {
            //             let value = pattern[i].value
            //                 .replace(/，/gi, ",")
            //                 .split(",");
            //             this.superIntentInputWords.push({
            //                 varName: pattern[i].varName,
            //                 type: pattern[i].type,
            //                 words: value,
            //                 isFillIn: pattern[i].isFillIn,
            //             });
            //         } else if (this.selectData.id == "") {
            //             this.$message.error("请选择超级意图类别！");
            //             return;
            //         } else {
            //             this.$message.error(
            //                 "填空处不能为空，请填写内容后再保存！"
            //             );
            //             return;
            //         }
            //     }
            // }
            let list = [];
            this.classification.forEach((item,index) => {
                // 1 新建   2 添加至已有
                if(this.useType === 1){
                    list.push(item.slice(1,item.length))
                } else {
                    if(index < 3){
                        list.push(item.slice(1,item.length))
                    } else {
                        list.push(item)
                    }
                }
            })
            this.saveBtnLoading = true;
            // let text = this.intentAnswerValue.replace('<figure class=\"image\">','');
            // text = text.replace('</figure>','');
            // console.log(text,'text');
            let intentAnswerValue = '',text='';
            if(this.intentAnswerType == 1){
                text = this.intentAnswerValueInput;
            } else {
                text = this.intentAnswerValue.replace('<figure class=\"image\">','');
                text = text.replace('</figure>','');
            }
            let params = {
                actionType: this.intentAnswerType == 1 ? 'answer_text' : 'answer_rich_text',
                botId: list[0],
                // config: JSON.stringify(this.superIntentInputWords),
                exampleQuestion: this.exampleQuestion,
                intentAnswer: text,
                intentAnswerSave: this.intentAnswer,
                intentId: list[3],
                intentName: this.intentName,
                resultId: this.activeMarkItem.id,
                // share: this.isShare,
                skillId: list[1],
                // templateId: this.currentSuperIntentList.enable ? this.selectData.id : '',
                typeId: list[2],
                useType: this.useType,
                documentId:this.activeMarkItem.documentId,
                taskGroupId:this.taskDetailObj.taskGroupId
            }
           
            let answer_text_obj = {
                    "actionList":[
                        {
                            "conditionList":[],
                            "content":{
                                "list":[text],
                                "extInfos":[{
                                    "expectedIntention":"",
                                    "notAsExpectedReply":""
                                }],
                                "recommend":{
                                    "list":[{
                                        "id":"",
                                        "type":0,
                                        "text":"",
                                        "ext":{
                                            "intentValue":[]
                                        }
                                    }],
                                    "status":false,
                                    "description":""
                                }
                            },
                            "type":"answer_text",
                        }
                    ],
                    "intentId":"141452"
            }
            let answer_rich_text_obj = {
                "actionList":[
                    {
                        "conditionList":[],
                        "content":{
                            "html":text,
                            "recommend":{
                                "list":[
                                    {
                                        "id":"",
                                        "type":0,
                                        "text":"",
                                        "ext":{
                                            "intentValue":[]
                                        }
                                    }
                                ],
                                "status":false,
                                "description":""
                            },
                            "markdown":"1"
                        },
                        "type":"answer_rich_text",
                        "effectiveTime":{
                            "range":"PERMANENT",
                            "startTime":"",
                            "endTime":"",
                           "repeat":["1","2","3","4","5","6","7"],
                            "filterHolidays":true,
                            "balanceHolidays":true,
                            "timeRange":[
                                new Date(
                                    "Thu Dec 15 2019 00:00:00 GMT+0800 (中国标准时间)"
                                ),
                                new Date(
                                    "Thu Dec 15 2100 23:59:59 GMT+0800 (中国标准时间)"
                                ),
                            ]
                        }
                    }
                ],
            }
            let effectiveTime = {
                "effectiveTime":{
                    "range":"PERMANENT",
                    "startTime":"",
                    "endTime":"",
                    "repeat":["1","2","3","4","5","6","7"],
                    "filterHolidays":true,
                    "balanceHolidays":true,
                    "timeRange":[
                        new Date(
                            "Thu Dec 15 2019 00:00:00 GMT+0800 (中国标准时间)"
                        ),
                        new Date(
                            "Thu Dec 15 2100 23:59:59 GMT+0800 (中国标准时间)"
                        ),
                    ]
                }
            }
            answer_text_obj = Object.assign(answer_text_obj,effectiveTime)
            answer_rich_text_obj = Object.assign(answer_rich_text_obj,effectiveTime)
            if(this.intentAnswerType == 1){
                params.action = answer_text_obj;
            } else {
                params.action = answer_rich_text_obj;
            }
           this.FetchPost(this.requestUrl.documentExtract.addExtractingResult,params).then(res => {
                if (res.code === "0") {
                    this.saveBtnLoading = false;
                    this.$message({
                        message: '添加抽取结果成功',
                        type: 'success',
                        duration:1500
                    });
                    this.$emit('addExtractingResultSuccess')
                  this
                } else {
                    this.saveBtnLoading = false;
                    this.$message.error(res.message);
                }
            });
        },
        // 预览图片
        previewImage(item){
            if (item && item !== '') {
                this.showPreview = true;
                this.previewImageUrl = item.url;
                
            } else {
                this.showPreview = false;
            }
        },
        // 复制图片
        copyImage(item){
            let clipboard = new Clipboard('.copy-img', {
                text: function () {
                    let url = document.querySelector('.html-content-image'+item.id + ' img').getAttribute('src')
                    console.log(url,'url');
                    let imgDom = document.createElement("img");
                    imgDom.setAttribute('src',url)
                    console.log(imgDom);
                    return document.querySelector('.html-content-image'+item.id)
                    // return imgDom;
                }
            })
            clipboard.on('success', e => {
                console.log('Text:', e.text)
                // this.$message.success('复制成功');
                // 释放内存
                clipboard.destroy()
            })
            clipboard.on('error', e => {
                // this.$message.error('复制失败');
                clipboard.destroy()
            })
        }
    }
}
</script>

<style scoped lang="less">
@import '../../../assets/less/main/common.less';
.add-extract-result{
    /deep/ .popup-floating-layer {
        #popup-assembly{
            width: 1040px !important;
            .popup-container{
                overflow-y:hidden;
            }
        }
        .popup-box{
            display: flex;
            max-height: calc(84vh - 200px)
        }
        .left-detail{
            padding-right: 10px;
            // height: 540px;
            //  height: 490px;
            width: 530px;
            overflow-y: auto;
            overflow-x: hidden;
            &.add-had{
                // height: 490px;
            }
            .addIntentInfo,.superIntentConfig-add-inetent{
                font-size: 14px;
                .item-config{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    margin-bottom: 20px;
                    font-size: 14px;
                    .title{
                        color: #616161;
                    }
                    .guoran-tongyichicun-shili{
                        color: #366AFF;
                    }
                    .block-add-intent{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        .title{
                            text-align: left;
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                        }
                    }
                    .blockdes{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        .title{
                            text-align: left;
                            margin-bottom: 4px;
                            width: auto;
                            color: #616161;
                            i{
                                margin-right: 5px;
                            }
                        }
                        .des{
                            color: #A9B3C6;
                            text-align: left;
                        }
                        .arsenal_icon{
                            color: #366AFF;
                        }
                    }
                    .config{
                        margin-top: 20px;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        align-items: center;
                        background: #F6F8FD;
                        border: 1px solid #E0E6F7;
                        border-radius: 5px;
                        position: relative;
                        .pattern{
                            text-align: left;
                            font-size: 14px;
                            margin-right: 0;
                            padding: 3px 0;
                            margin-left: 6px;
                            /deep/.el-input {
                                flex: auto;
                                min-width: 120px;
                                max-width: 350px;
                            }
                            .el-input__inner{
                                // flex: auto;
                                // border-top: none;
                                // border-left: none;
                                // border-right: none;
                                // border-radius: 5px 5px 0 0;
                                // border-color: #366AFF;
                                border-bottom: solid 2px #366AFF;
                            }
                            .word{
                                font-size: 12px;
                                flex: none;
                                text-align: left;
                                white-space: nowrap;
                                margin: 0 4px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .keyword-recommend{
                                position: relative;
                            }
                        }
                    
                    }
                    .example{
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        color: #aaaaaa;

                        .pattern{
                            width: auto;
                            text-align: left;
                            margin-right: 20px;
                            .word{
                                text-align: left;
                                margin-top: 20px;
                            }
                            .input{
                                //text-decoration:underline;
                                font-size: 14px;
                                border-bottom: 1px solid #aaaaaa;
                            }
                        }
                    }
                    .classification{
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            .title{
                                text-align: left;
                                margin-bottom: 10px;
                            }
                    }
                
                    .switch{
                        text-align: left;
                        flex: none;
                        width: 150px;
                    }
                    .titleDes{
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        em{
                            text-align: right;
                            margin-right: 45px;
                            font-size: 12px;
                            color: @system_fontcolor_sub;
                        }
                    }
                    .config-example{
                        margin-top: 30px;
                        border: 1px solid #E0E6F7;
                        border-radius: 5px;
                        .title{
                            height: 35px;
                            border-bottom: 1px solid #E0E6F7;
                            line-height: 35px;
                            text-align: center;
                            width: 100%;
                        }
                    }
                    .bottom-example{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 16px;
                        
                        .pattern{
                            height: 33px;
                            line-height: 33px;
                            padding: 0 12px;
                            margin: 0 3px;
                            &.border{
                                background: #F6F8FD;
                                border: 1px solid #ACC2FF;
                                border-radius: 4px;
                                font-size: 14px;
                                color: #366AFF;
                            }
        
                        }
                    }
                    .intent-answer-box{
                         
                        .top,.middle{
                            display: flex;
                            height: 24px;
                            align-items: center;
                            justify-content: space-between;
                            padding-right: 40px;
                            margin-bottom: 10px;
                            .edit{
                                margin-right: 56px;
                            }
                            &.middle{
                                margin-top: 10px;
                                padding-right: 0;
                            }
                        }
                        .bgc-box{
                            background: #FBFCFD;
                            border-radius: 5px;   
                            padding: 10px; 
                        }
                        .intent-answer-value{
                            .ck-editor__editable{
                                height: 150px!important;
                            }
                             .ck-content .image img {
                                width: 80px;
                                height: 80px;
                                object-fit: cover;
                            }
                        }
                    }
                    &.add-had{
                        background:#FBFCFD;
                        border-radius: 5px;
                        padding: 16px 11px;
                        margin-bottom: 6px;
                        .qa-name{
                            font-size: 14px;
                            color: #FF7200;
                            margin-bottom: 12px;
                            text-align: left;
                        }
                        .divider{
                            height: 1px;
                            border-bottom: 1px dotted #E0E6F7;
                            margin-bottom: 10px;
                        }
                    }
                }
                .line{
                    margin: 30px 0;
                    height: 2px;
                    border-bottom: 1px dotted #E0E6F7;
                }
                .exmplebox{
                    background: #FFFFFF;
                    border: 1px solid #E0E6F7;
                    border-radius: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    font-size: 14px;
                    .title{
                        width: 100%;
                        text-align: center;
                        height: 35px;
                        line-height: 35px;
                        border-bottom: 1px solid #E0E6F7;
            
                    }
                    .exmple{
                        text-align: left;
                        flex: none;
                        height: 120px;
                        overflow-y: scroll;
                        overflow-x: hidden;
                        width: calc(100% - 20px);
                        padding: 10px;
                        .text{
                            margin: 0px 0px 10px 0px;
                            color: #A9B3C6;
                            display: flex;
                            height: 30px;
                            align-items: center;
                            >div{
                                width: 6px;
                                height: 6px;
                                background: #A9B3C6;
                                border-radius: 50%;
                                margin-right: 10px;
                            }
                        }
                    }
                }
                .flexrow{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                }
                .copy-intent-btn{
                    .guoran-tongyichicun-fuzhi{
                        margin-right: 5px;
                    }
                }
                .red-text{
                    color: red;
                    font-size: 14px;
                    margin-left: 3px;
                    margin-top: 3px;
                }
                .block-add-intent .right{
                    display: flex;
                    flex-direction: column;
                    .desc{
                        font-size: 14px;
                        text-align: left;
                        margin-left: 0;
                        margin-top: 5px;
                        padding-right: 10px;
                    }
                }
                .share-radio{
                    .el-radio-group{
                        display: flex;
                        align-items: center;
                        height: 40px;
                        background: #FBFCFD;
                        border-radius: 5px;
                        text-align: left;
                        padding-left: 10px;
                    }   
                }
                .tips{
                    height: 62px;
                    padding: 16px 12px 20px 9px;
                    background: #FBFCFD;
                    height: 37px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #A9B3C6;
                    line-height: 22px;
                    text-align: left;
                }
                .open-super-intent .right{
                    height: 40px;
                    background: #FBFCFD;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    padding-left: 12px;
                    .desc{
                        color: #A9B3C6;
                        margin-left: 12px;
                        height: 24px;
                        white-space: nowrap;
                    }

                }
                .evaluate-table-switch {
                    display: flex;
                    flex-direction: row;
                    height: 25px;
                    justify-content: space-between;
                    cursor: pointer;
                    position: relative;
                    text-align: center;
                    align-items: center;
                    margin-left: 20px;
                    .el-switch {
                        text-align: center;
                    }
            
                    .switch-open-icon {
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 3px;
            
                        i {
                            color: white;
                            font-size: 16px;
                        }
                    }
            
                    .switch-close-icon {
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 21px;
                        i {
                            color: #6E6B7B;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
            
                    i {
                        color: #366AFF;
                        font-size: 18px;
                    }
                    .desc{
                        color: #A9B3C6;
                        margin-left: 10px;
                    }
                }
            }
            .recommend-intent{
                display: block;
                margin-bottom: 20px;
                color: #616161;
                font-size: 14px;
                text-align: left;
                // max-height: 230px;
                 .title{
                    text-align: left;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                }
                .recommend-intent-content{
                    max-height: 194px;
                    height:64px;
                    overflow-x: hidden;
                    overflow-y: auto;
                    background: #FBFCFD;
                    border-radius: 5px;
                    padding: 12px 15px 0;
                    .checked-intent-item{
                        line-height: 21px;
                        display: flex;
                        .intent-names{
                            width: 100%;
                            text-align: left;
                            font-size: 14px;
                            .bot-name{
                                color: #A9B3C6;
                                height: 20px;
                            }
                            .intent-name{
                                margin-top: 10px;
                                margin-bottom: 14px;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                width: 100%;
                                height: 20px;
                            }
                        }
                        .iconfont,.el-icon-success{
                            color: #366AFF;
                            cursor: pointer;
                            line-height: 42px;
                            font-size: 15px;
                        }
                    }
                    .el-loading-mask{
                        .el-loading-spinner{
                            top: 40%;
                        }
                        .circular{
                            width: 30px;
                            height: 30px;
                        }
                    }
                    &.animateBoo{
                        animation: hideRecommendContent .8s ease 1; /*调用动画：动画名、时间、时间线条、播放次数*/
                        animation-fill-mode: forwards;/*定义动画结束的状态*/
                    }
                    @keyframes hideRecommendContent {
                        0%{height: 64px;padding: 12px 15px 0;}
                        100%{height: 0;padding: 0;}
                    }
                     &.no-data{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #FF7200;
                        padding: 0;
                        .no-data{
                            color: #FF7200;
                        }
                    }
                    &.have-data{
                        max-height: 194px;
                        height:64px;
                        height: auto;
                    }
                }
                .no-checked{
                    width: 15px;
                    height: 15px;
                    background: #FFFFFF;
                    border: 1px solid #E0E6F7;
                    border-radius: 50%;
                    cursor: pointer;
                    margin-left: -3px;
                }
                &.hide-box{
                    height: 0;
                    margin-bottom: 0;
                    display: none;
                }
            }
            .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                &.classification-title{
                    justify-content: flex-start;
                }
                .left{
                    color: #616161;
                    display: flex;
                    align-items: center;
                    .red-text{
                        color: red;
                        margin-left: 3px;
                        margin-top: 5px;
                    }
                }
                .right-tips{
                    font-size: 14px;
                    color: #A9B3C6;
                }
            }
        }
       .keyword-ecommend-content-right{
            // max-height: calc(84vh - 300px);
            // height: 540px;
            // height: 490px;
            width: 300px;
            background: #F5F7FB;
            margin-left: 20px;
            padding: 0 30px;
            // min-height: 378px;
            overflow-y: auto;
            &.add-had{
                // height: 490px;
            }
            .keyword-ecommend-content-right-title{
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #366AFF;
                line-height: 34px;
                margin-bottom: 20px;
                margin-top: 30px;
                .guoran-tongyichicun-jiqirentouxiang{
                    margin-right: 5px;
                }
            }
            .checked-intent{
                background: #FFFFFF;
                border: 1px solid #E0E6F7;
                border-radius: 3px;
                .checked-intent-title{
                    height: 35px;
                    background: #F5F7FB;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    text-align: center;
                    line-height: 35px;
                    color: #616161;
                    &.answer{
                        background-color: #E6EEFF;
                    }
                }
                .checked-intent-box{
                   .checked-intent-item{
                        height: 42px;
                        line-height: 42px;
                        background: #FFFFFF;
                        box-shadow: 0px 1px 0px 0px #E0E6F7;
                        padding: 4px 10px 4px 15px;
                        display: flex;
                        // align-items: center;
                        justify-content: space-between;
                        color: #616161;
                        font-size: 14px;
                        border-bottom: 1px solid #E0E6F7;
                        .intent-names{
                            width: 100%;
                            text-align: left;
                            .bot-name{
                                color: #A9B3C6;
                                font-size: 12px;
                            }
                        }
                        .iconfont,.el-icon-success{
                            color: #366AFF;
                            cursor: pointer;
                            line-height: 42px;
                            font-size: 14px;
                        }
                        &:last-child{
                            border: none;
                        }
                        &.answer{
                            height: auto;
                            line-height: 25px;
                        }
                   }
                }
                
                &.verbatim-texts{
                    margin-top: 30px;
                    .verbatim-texts-box{
                        padding: 16px;
                        img{
                            height: 100%;
                        }
                        .top-img-box-list,.bottom-img-box-list{
                            width: 260px;
                            position: relative;
                            &:last-child{
                                /deep/ .el-image{
                                    width: 260px;
                                    margin-bottom: 0 !important;
                                    img{
                                        width: 260px;
                                    }
                                }
                            }
                        }
                        /deep/ .el-image{
                            width: 260px !important;
                            img{
                                width: 260px !important;
                            }
                        }
                        .operation-btn{
                            width: calc(100% - 10px);
                            position: absolute;
                            top: 0;
                            height: 28px;
                            background: rgba(0, 0, 0, 0.35);
                            border-radius: 1px;
                            color: #ffff;
                            font-size: 14px;
                            display: flex;
                            padding-right: 10px;
                            align-items: center;
                            justify-content: flex-end;
                            .copy-img{
                                margin-left: 25px;
                                cursor: pointer;
                            }
                            .preview-img{
                                cursor: pointer;
                            }
                            i{
                                color: #ffff;
                                vertical-align: middle;
                            }
                        }
                        .verbatim-texts-text{
                            line-height: 20px;
                            color: #616161;
                            text-align: left;
                            margin-bottom: 10px;
                            .active-text{
                                background-color: #CDDCFF;
                                text-align: left;
                            }

                        }
                        
                    }
                }
            }
        }
    }
}
</style>