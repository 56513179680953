<!--任务构建中-->
<template>
  <div class="task-structure">
    <el-drawer
        v-if="drawerTaskStructure"
        class="drawer-task-structure"
        key="intent-task-structure"
        title="任务构建中"
        size="690px"
        show-close
        direction="rtl"
        :visible.sync="drawerTaskStructure"
        :close-on-press-escape="false"
        :wrapperClosable="false"
        :with-header="true">
        <div class="notice-drewer-header">
            <img src="../../../assets/images/building.png" alt="">
            <div class="title">任务构建中</div>
            <div class="header-detail">
                <div class="task-name one-column-ellipsis">{{taskStructureInfo.taskName}}</div>
                <i class="iconfont guoran-a-16-15"></i>
                <i class="iconfont guoran-a-18-13"></i>
            </div>
            <div class="close">
                <span @click="handleCloseTaskStructure" class="el-icon-close"></span>
            </div>
        </div>
        <div class="task-structure-content">
            <div class="task-structure-list">
                <div class="task-structure-item success" v-for="(item,index) in fileList" :key="index">
                    <img v-if="item.type === 'xlsx'" src="../../../assets/images/document-excl.png" alt="">
                    <img v-else-if="item.type === 'pdf'" src="../../../assets/images/document-pdf.png" alt="">
                    <img v-else-if="item.type === 'txt'" src="../../../assets/images/document-txt.png" alt="">
                    <img v-else-if="item.type === 'docx'" src="../../../assets/images/document-word.png" alt="">
                    <span class="file-name one-column-ellipsis">文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档1111名称文档名称文档名1111称文档名称文档名称文档名称1111</span>
                    <i class="el-icon-success"></i>
                </div>
                <div class="task-structure-item progress-box">
                    <div class="top-box">
                        <img src="https://img0.baidu.com/it/u=162734124,608409399&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=750" alt="">
                        <span class="file-name one-column-ellipsis">文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档1111名称文档名称文档名1111称文档名称文档名称文档名称1111</span>
                    </div>
                    <el-progress :percentage="60"></el-progress>
                </div>
                <div class="task-structure-item progress-box">
                    <div class="top-box">
                        <img src="https://img0.baidu.com/it/u=162734124,608409399&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=750" alt="">
                        <span class="file-name one-column-ellipsis">文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档名称文档1111名称文档名称文档名1111称文档名称文档名称文档名称1111</span>
                    </div>
                    <el-progress :percentage="60"></el-progress>
                </div>
            </div>
            <div class="bottom-time">
                预计<span>12</span>分钟后进行知识抽取
            </div>
        </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
    props:{
		drawerTaskStructure:{
			type:Boolean,
			default:false
		},
        taskStructureInfo:{
            type:Object,
            default(){
                return {}
            }
        },
        taskDetail:{
            type:Object,
            default(){
                return {}
            }
        }
	},
    data(){
        return{
            fileList:[{
                type:'xlsx'
            },{
                type:'pdf'
            },{
                type:'docx'
            },{
                type:'pdf'
            }],
            timer:null
        }
    },
    watch:{
        drawerTaskStructure(n){
            if(n){
                clearInterval(this.timer)
                // taskStructureInfo

            }
        },
        
    },
    methods:{
        // 获取进度
        getProgress(){
            this.timer = setInterval(() => {
                this.FetchGet(this.requestUrl.documentExtract.getProgress).then(res => {
                    if (res.code === "0") {
                        // clearInterval(this.timer)
                    
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                });
            },1000)
        },
        handleCloseTaskStructure(){
            this.$emit('update:drawerTaskStructure',false);
        }
    }
}
</script>

<style scoped lang='less'>
.task-structure{
    /deep/ .el-drawer{
        .el-drawer__header{
            height: 0;
            padding: 0;
            margin: 0;
            overflow: hidden; 
        }
        .notice-drewer-header{
            img{
                width: 19px;
                height: 19px;
                margin-right: 7px;
            }
            .header-detail{
                margin-left: 18px;
                display: flex;
                align-items: center;
                .task-name{
                    width: 300px;
                    height: 14px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #A9B3C6;
                    line-height: 14px;
                    margin-right: 18px;
                }
                .iconfont{
                    cursor: pointer;
                    color: #366AFF;
                    &:last-child{
                        margin-left: 12px;
                    }
                }
            }
        }
        .task-structure-content{
            margin: 20px;
            height: calc(100vh - 110px);
            background: #FFFFFF;
            border: 1px solid #E0E6F7;
            border-radius: 5px;
            .task-structure-list{
                height: calc(100% - 45px);
                overflow-x: hidden;
                overflow-y: auto;
                .task-structure-item{
                    display: flex;
                    img{
                        width: 18px;
                        height: 18px;
                        margin-right: 10px;
                        flex-shrink: 0;
                    }
                    .file-name{
                        font-size: 14px;
                        font-weight: 400;
                        color: #000000;
                        flex: 1;
                        text-align: left;
                        height: 14px;
                        line-height: 15px;
                    }
                    .el-icon-success{
                        margin-left: 10px;
                        color: #749BFF;
                        font-size: 18px;
                    }
                    &.success{
                        height: 52px;
                        padding: 0 11px;
                        border-bottom: 1px solid #E0E6F7;
                        background: rgba(54, 106, 255, 0.07);
                        align-items: center;
                    }
                    &.progress-box{
                        flex-direction: column;
                        .top-box{
                            margin-bottom: 11px;
                            margin-top: 11px;
                            display: flex;
                            padding: 0 11px;
                        }
                        .el-progress{
                            .el-progress-bar__outer{
                                height: 3px !important;
                                left: -12px;

                            }
                            .el-progress__text{
                                display: none;
                            }
                        }
                        
                    }
                    &:first-child{
                        border-left: 3px solid #366AFF;
                    }
                }
            }
            .bottom-time{
                height: 45px;
                background: #F9FBFF;
                border-radius: 1px 1px 5px 5px;
                font-size: 14px;
                color: #000000;
                display: flex;
                justify-content: center;
                align-items: center;
                span{
                    color: #366AFF;
                }
            }
        }
    }
}
</style>